import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getCatalystData} from "./services/stocks_service";
import {ReactECharts} from "../utils/echarts_util";
import {nFormatter} from "../utils/values_formats";

const TableCellNoBorder = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
}));

const TableData = (props) => {
    const {monthly, quarterly, yearly} = props.data;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Monthly</TableCell>
                        <TableCell align="center">Quarterly</TableCell>
                        <TableCell align="center">Yearly</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center">{nFormatter.format(monthly)}</TableCell>
                        <TableCell align="center">{nFormatter.format(quarterly)}</TableCell>
                        <TableCell align="center">{nFormatter.format(yearly)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TableUpcomingData = (props) => {
    const events = props.data || [];

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Event</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        events.map(item => (
                            <TableRow>
                                <TableCellNoBorder align="center">{item.date.split("T")[0]}</TableCellNoBorder>
                                <TableCellNoBorder align="left">{item.name}</TableCellNoBorder>
                            </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export function CatalystTabComponent(props) {
    const {ticker} = props;
    const [loading, setLoading] = useState(true);
    const [catalyst, setCatalyst] = useState({});

    const getData = async () => {
        setLoading(true);
        const {data} = await getCatalystData(ticker);
        setCatalyst(data);
        setLoading(false);
    };

    useEffect(() => {
        if (Object.keys(catalyst).length > 0) return;
        getData();
    }, []);

    const option = {
        animation: false,
        title: {
            text: "Press releases per year",
            left: 'center'
        },
        xAxis: {
            type: 'category',
            data: Object.keys(catalyst).length > 0 ? catalyst.releases_per_year.map(item => item["year"]) : [],
        },
        yAxis: {
            type: 'value',
        },
        series: {
            type: 'bar',
            data: Object.keys(catalyst).length > 0 ? catalyst.releases_per_year.map(item => item["count"]) : [],
            label: {
                show: true,
                position: 'inside'
            },
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        <Grid item xs={6}>
                            <Card>
                                <CardHeader title="Upcoming events"/>
                                <CardContent>
                                    <ReactECharts
                                        option={option}
                                        style={{height: window.innerHeight * 0.3, width: "100%"}}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card>
                                <CardHeader title="Upcoming events"/>
                                <CardContent>
                                    <TableUpcomingData data={catalyst.upcoming_events}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider>Average press releases / period</Divider>
                        </Grid>
                        <Grid item xs={4}>
                            <Card>
                                <CardHeader title="Total"/>
                                <CardContent>
                                    <TableData data={catalyst.total}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card>
                                <CardHeader title="Earning Related"/>
                                <CardContent>
                                    <TableData data={catalyst.earning_related}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card>
                                <CardHeader title="Non-Earning Related"/>
                                <CardContent>
                                    <TableData data={catalyst.non_earning_related}/>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
            }
        </Grid>
    );
}