import React, {useState} from "react";
import {getKpi} from "./ai_service";
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import cyan from "@mui/material/colors/cyan";

export function KPIComponent(props) {
    const {ticker} = props;
    const [loading, setLoading] = useState(false)
    const [answer, setAnswer] = useState([])

    const fetchData = async () => {
        try {
            if (answer.length > 0) return;
            setLoading(true);
            const {data} = await getKpi(ticker);
            setAnswer(data);
        } finally {
            setLoading(false);
        }
    };

    return <List dense={true}>
        <ListItem>
            <ListItemButton disabled={loading} divider={true} onClick={fetchData} sx={{backgroundColor: cyan[200]}}>
                <ListItemText primary="Request AI KPI"/>
            </ListItemButton>
        </ListItem>
        <ListItem>
            <List component="div" disablePadding>
                {
                    answer.map(a => <ListItemText primary={a}/>)
                }
            </List>
        </ListItem>
    </List>

}