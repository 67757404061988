import React, {useEffect, useState} from "react";
import {getChicagoStressIndexData} from "../service/stress";
import {getRecessionAreas} from "../economics_leading/utils";
import {Skeleton} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {ReactECharts} from "../utils/echarts_util";

export function ChicagoStressIndexComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(false);
    const [stressIndex, setStressIndex] = useState([]);

    const getData = async () => {
        setLoading(true);
        const {data} = await getChicagoStressIndexData();
        setStressIndex(data);
        setLoading(false);
    };

    useEffect(() => {
        if (stressIndex.length > 0) return;
        getData();
    }, []);

    const dateList = stressIndex.map((item) => {
        return item["row"].split("T")[0];
    });

    const toPercentValue = (d) => d * 100;

    const dataList = stressIndex.map((item) => {
        return toPercentValue(item["chicago"]);
    });


    const option = {
        animation: false,
        title: {
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(stressIndex)
                },
            },
        ]
    };


    return loading ?
        <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReactECharts option={option}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </Grid>
}