import {combineReducers} from 'redux'
import classificationMappingReducer from './reducers/classificationMappingReducer'
import authReducer from "./reducers/authReducer";
import StocksReducer from "./reducers/stocksReducer";

const rootReducer = combineReducers({
    classifications: classificationMappingReducer,
    auth: authReducer,
    stocks: StocksReducer,
});

export default rootReducer;
