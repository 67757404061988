import {
    Autocomplete,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Portal,
    Select,
    Snackbar,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {getBetaData} from "./service";
import {Alert} from "@mui/lab";
import {nFormatter} from "../utils/values_formats";


const CardNestedStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(1),
}));

const AvailableBenchmarks = (props) => {
    const {benchmark, setBenchmark, benchmarks} = props;

    return (
        <FormControl variant="standard" sx={{m: 1}}>
            <InputLabel variant="standard" id="benchmark">
                Benchmark
            </InputLabel>
            <Select
                labelId="benchmark"
                id="benchmark-select"
                value={benchmark}
                label="Benchmark"
                onChange={(e) => setBenchmark(e.target.value)}
            >
                {
                    benchmarks.map((item, i) => (
                        <MenuItem value={i}>{item}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

const BetaTable = (props) => {
    const {beta} = props;

    return (
        <Card elevation={0}>
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    2 Years
                                </TableCell>
                                <TableCell align="center">
                                    3 Years
                                </TableCell>
                                <TableCell align="center">
                                    5 Years
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    {nFormatter.format(beta["2y"])}
                                </TableCell>
                                <TableCell align="center">
                                    {nFormatter.format(beta["3y"])}
                                </TableCell>
                                <TableCell align="center">
                                    {nFormatter.format(beta["5y"])}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}

export default function BetaComponent(props) {
    const [benchmark, setBenchmark] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ticker, setTicker] = useState(null);
    const [beta, setBeta] = useState(null);
    const stocks = useSelector(state => state.stocks.stocks);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(null);

    const tickers = stocks.tickers || [];
    const availableBenchmarks = [
        "S&P 500", "Russell 1000", "Russell 2000", "Russell 3000", "NASDAQ Composite", "NYSE COMPOSITE (DJ)",
        "Wilshire 5000 Total Market Index", "Dow Jones U.S. Total Stock Mark", "Dow Jones Industrial Average",
    ];

    const calculate = async () => {
        setLoading(true);
        try {
            const r = await getBetaData(ticker, benchmark);
            setBeta(r.data);
        } catch (err) {
            setSnackbarOpen(true);
            setSnackbarMsg(`${err.message}: ${err.response.data}`);
        } finally {
            setLoading(false);
        }
    }

    const handleTickerSelect = (event, value) => setTicker(value);
    const isReadyToCalculate = loading === false && Boolean(ticker);
    const dataPresent = Boolean(beta);

    return (
        <>
            <CardNestedStyled>
                <CardHeader title="Beta Calculation"/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} display="flex" justifyContent="right" alignItems="right">
                            <AvailableBenchmarks
                                benchmark={benchmark}
                                setBenchmark={setBenchmark}
                                benchmarks={availableBenchmarks}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                freeSolo
                                id="find-ticker"
                                options={tickers}
                                onChange={handleTickerSelect}
                                sx={{minWidth: 150, m: 1}}
                                renderInput={
                                    (params) => <TextField
                                        {...params}
                                        variant="standard"
                                        label="Ticker"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={calculate} disabled={!isReadyToCalculate}>
                                Calculate
                            </Button>
                        </Grid>
                        {
                            loading ?
                                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress/>
                                </Grid>
                                :
                                dataPresent ?
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                        <BetaTable beta={beta}/>
                                    </Grid> : ""
                        }
                    </Grid>
                </CardContent>
            </CardNestedStyled>
            <Portal>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                          open={snackbarOpen}
                          autoHideDuration={6000}
                          onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{width: '100%'}}>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
            </Portal>
        </>

    );
}