import {ReactECharts} from "../utils/echarts_util";
import React from "react";
import * as echarts from 'echarts';

export function PerformanceComponent(props) {
    const {performance, portfolioPrice, height} = props;

    const nonBalancedDateList = performance.nrb_index.date.map(item => item.split("T")[0]);

    const nonBalancedValueList = performance.nrb_index.performance;

    const toOption = (dateList, valueList, title) => ({
        title: {
            subtext: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList,
        },
        yAxis: {
            type: 'value',
            min: Math.round(Math.min(...valueList) - 1000),
            max: Math.round(Math.max(...valueList) + 1000),
        },
        series: {
            type: 'line',
            showSymbol: false,
            data: valueList,
            markLine: {
                data: [{
                    name: 'Price at the beginning',
                    yAxis: portfolioPrice
                }]
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgba(58,77,233,0.8)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(58,77,233,0.3)'
                    }
                ])
            }
        }
    });

    return <ReactECharts option={toOption(nonBalancedDateList, nonBalancedValueList, "No rebalancing during period")}
                         style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>;
}