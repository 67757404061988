import React, {useEffect, useState} from "react";
import {getInflationData} from "../service/economics";
import {getRecessionAreas} from "../economics_leading/utils";
import {ReactECharts} from "../utils/echarts_util";
import Grid from "@mui/material/Unstable_Grid2";
import {Skeleton} from "@mui/material";

export function InflationComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [inflation, setInflation] = useState([]);

    const getData = async () => {
        const {data} = await getInflationData();
        setInflation(data);
        setLoading(false);
    };

    useEffect(() => {
        if (inflation.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = inflation.map((item) => {
        return item["row"].split("T")[0];
    });

    const toPercentValue = (d) => d * 100;

    const coreCpi = inflation.map((item) => {
        return toPercentValue(item["core_cpi"]);
    });

    const corePce = inflation.map((item) => {
        return toPercentValue(item["core_pce"]);
    });

    const ppi = inflation.map((item) => {
        return toPercentValue(item["ppi"]);
    });

    const toOption = (dataList, title) => ({
        animation: false,
        title: {
            text: title,
            subtext: "Change",
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: "{value} %"
            }
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(inflation)
                },
            },
        ]
    });


    return loading ?
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </Grid>
        :
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <ReactECharts option={toOption(coreCpi, "Core: Consumer Price Index")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(corePce, "Core: Personal Consumption Expenditures")}
                              style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(ppi, "Total: Producer Price Index")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
        </Grid>
}