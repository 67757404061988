import {tryGetAuthorized} from "./requests";

export const getIsmDetailsReq = (periods=6) => {
    const url = "/ism/details";
    return tryGetAuthorized(url);
}

export const getNonfarmIdeasReq = () => {
    const url = "/idea/nonfarm";
    return tryGetAuthorized(url);
}