import React from "react";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function About() {
    return (
        <Container maxWidth={false}>
            <Typography variant="h4" gutterBottom>About</Typography>
            <Typography variant="body1" align="left">
                A set of utilities which i'm using for my own researches.<br/> Since i'm not earning money from this project updates are random.
            </Typography>
        </Container>
    );
}