import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {hasPremium} from "../../service/authService";
import {Card, CardContent, CardHeader, CircularProgress, Tab, Tabs} from "@mui/material";
import {getIsmDetailsReq} from "../../service/idea_generation";
import Grid from "@mui/material/Unstable_Grid2";
import {ScoreSectionComponent} from "./scoreComponent";
import {TabPanel} from "../../utils/tabs_utils";
import {CommentsComponent} from "./commentsComponent";
import {CardDefaultMarginStyled} from "../../config/constants";
import {CandidatesComponent} from "./candidatesComponent";

export default function IsmDetailsComponent(props) {
    const user = useSelector(state => state.auth.user);
    const [ismDetails, setIsmDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const isPremium = hasPremium(user);
    const [tabIndex, setTabIndex] = useState(0);
    const [tableRowSelection, setTableRowSelection] = useState("");

    const fetchData = async () => {
        const response = await getIsmDetailsReq();
        setIsmDetails(response.data);
        setLoading(false);
    };

    useEffect(() => {
        if (!isPremium) {
            navigate("/");
        } else if (Object.keys(ismDetails).length > 0) return;

        setLoading(true);
        fetchData();
    }, []);

    return (
        <CardDefaultMarginStyled elevation={0}>
            <CardHeader title="Idea generation"/>
            <CardContent>
                <Grid container spacing={1}>
                    {
                        loading ?
                            <Grid item xs={12} centered><CircularProgress/></Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Tabs value={tabIndex}
                                          onChange={(event, newValue) => setTabIndex(newValue)}
                                          centered
                                    >
                                        <Tab label="Manufacturing" value={0}/>
                                        <Tab label="Services" value={1}/>
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12} sx={{p: 0}}>
                                    <TabPanel value={tabIndex} index={0}>
                                        <Card elevation={0}>
                                            <CardHeader title="Manufacturing"/>
                                            <CardContent>
                                                <ScoreSectionComponent
                                                    tableRowSelection={tableRowSelection}
                                                    setTableRowSelection={setTableRowSelection}
                                                    scores={ismDetails.Manufacturing.scores}/>
                                                <CandidatesComponent topLvl={"Manufacturing"} />
                                                <CommentsComponent
                                                    comments={ismDetails.Manufacturing.comments} />
                                            </CardContent>
                                        </Card>
                                    </TabPanel>
                                </Grid>
                                <Grid item xs={12} sx={{p: 0}}>
                                    <TabPanel value={tabIndex} index={1}>
                                        <Card elevation={0}>
                                            <CardHeader title="Services"/>
                                            <CardContent>
                                                <ScoreSectionComponent
                                                    tableRowSelection={tableRowSelection}
                                                    setTableRowSelection={setTableRowSelection}
                                                    scores={ismDetails.Services.scores}/>
                                                <CandidatesComponent topLvl={"Services"} />
                                                <CommentsComponent
                                                    comments={ismDetails.Services.comments} />
                                            </CardContent>
                                        </Card>
                                    </TabPanel>
                                </Grid>
                            </>
                    }
                </Grid>
            </CardContent>
        </CardDefaultMarginStyled>
    );
}