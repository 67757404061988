import { getNaicsClassification, getGicsClassification } from '../../service';
import {FETCH_ERROR, FETCH_NAICS, FETCH_GICS} from "../types"

export const fetchNaics = () => async (dispatch) => {
    try {
        const resp = await getNaicsClassification();
        dispatch({type: FETCH_NAICS, payload: resp.data});
    } catch(e) {
        dispatch({
            type: FETCH_ERROR,
            payload: console.log(e),
        });
    }
};

export const fetchGics = () => async (dispatch) => {
    try {
        const resp = await getGicsClassification();
        dispatch({type: FETCH_GICS, payload: resp.data});
    } catch(e) {
        dispatch({
            type: FETCH_ERROR,
            payload: console.log(e),
        });
    }
};