import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Collapse,
    IconButton,
    Portal,
    Snackbar,
    styled,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    useTheme
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Divider from "@mui/material/Divider";
import React, {useState} from "react";
import {green, red} from "@mui/material/colors";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from "@mui/material/Unstable_Grid2";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {requestModelPortfolio} from "./portfolioService";
import CloseIcon from '@mui/icons-material/Close';
import PeriodComponent from "../_common/periodComponent";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {CorrelationTable} from "./tables";
import {percentFormatterOneDigit} from "../utils/values_formats";
import {VolatilityComponent} from "./volatility";
import {PerformanceComponent} from "./performance";

const CardStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(1),
}));

const DividerStyled = styled(Divider)(({theme}) => ({
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
}));

const PaperStyled = styled(Card)(({theme}) => ({
    display: 'flex',
    alignItems: 'center'
}));

const TextFieldWeightStyled = styled(TextField)(({theme}) => ({
    width: "12%",
    marginRight: theme.spacing(1),
}));

const Long = styled(ToggleButton)(({theme}) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: green[300]
    },
    border: 0
}));

const Short = styled(ToggleButton)(({theme}) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: red[300]
    },
    border: 0
}));

const TickerRow = (props) => {
    const {onChange, value, weight, tickers, position, id} = props;

    return (
        <PaperStyled elevation={0}>
            <TextField
                options={tickers}
                value={value}
                onChange={onChange}
                variant="standard"
                inputProps={{"data-type": "in", "data-id": id}}
                label="Ticker"
            />
            <DividerStyled orientation="vertical"/>
            <TextFieldWeightStyled
                disabled
                value={weight !== 0 ? percentFormatterOneDigit.format(weight) : ""}
                variant="standard"
                label=" "
                inputProps={{style: {textAlign: 'center'}}}
            />
            <ToggleButtonGroup
                size="small"
                exclusive
                value={position}
            >
                <Long size={"small"} onClick={onChange} isableRipple value="l" data-id={id} data-type="p">Long</Long>
                <Short size={"small"} onClick={onChange} value="s" data-id={id} data-type="p">Short</Short>
            </ToggleButtonGroup>
        </PaperStyled>
    );
}

const NoData = (props) => {
    const {open, close} = props;
    return (
        <Collapse in={open}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={close}
                    >
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                }
                severity="success" color="info"
            >
                No correlation data for current ticker(s) combination, please try another list of tickers.

            </Alert>
        </Collapse>
    );
}

export default function Portfolio() {
    const premium = useSelector(state => state.auth.hasPremiumAccess);
    const stocks = useSelector(state => state.stocks.stocks);

    const inputArr = [...Array(8).keys()].map(i => ({
        id: i + 1,
        ticker: "",
        position: "l",
        weight: 0
    }));
    const [tickersConfig, setTickersConfig] = useState(inputArr);
    const [modelingData, setModelingData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noData, setNoData] = useState(false);
    const [period, setPeriod] = useState("w");
    const [portfolioPrice, setPortfolioPrice] = useState(10000);
    const [lookPastYears, setLookPastYears] = useState(2);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(null);
    const theme = useTheme();

    const addInput = () => {
        setTickersConfig(s => {
            return [
                ...s,
                {
                    value: "",
                    position: "l",
                    weight: 0
                }
            ];
        });
    };

    const handleTickersConfigChange = (e, newVal) => {
        e.preventDefault();

        const index = e.target.getAttribute("data-id");
        const type = e.target.getAttribute("data-type");
        setTickersConfig(s => {
            const newArr = s.slice();
            if (type === "p") {
                newArr[index].position = newVal;
            } else if (type === "in") {
                newArr[index].ticker = e.target.value;
                recalculateWeights();
            }

            return newArr;
        });
    };

    const recalculateWeights = () => {
        const A = 1.0;
        const totalInserted = tickersConfig.filter(item => Boolean(item.ticker)).length;
        tickersConfig.forEach(item => {
            if (item.ticker) {
                item.weight = A / totalInserted;
            } else {
                item.weight = 0;
            }
        });
        setTickersConfig(tickersConfig);
    }

    const postData = async (data) => {
        try {
            const response = await requestModelPortfolio(data, period);
            if (response.status === 200 && response.data.corr.m) {
                setModelingData(response.data);
            } else {
                setModelingData(null);
                setNoData(true);
            }
        } catch (err) {
            setSnackbarOpen(true);
            const errMsg = err.response.data.detail || err.response.data[0];
            setSnackbarMsg(`${err.message}: ${errMsg}`);
        } finally {
            setLoading(false);
        }
    }

    const modelPortfolio = () => {
        if (clearedTickersConfig.length > 1) {
            setLoading(true);
            postData({
                "tickers_data": clearedTickersConfig,
                "target_exposure": portfolioPrice,
                "performance_years": lookPastYears
            });
        }
    }

    const onPeriodChange = (event) => {
        setPeriod(event.target.value);
    }
    const clearedTickersConfig = tickersConfig.filter(item => Boolean(item.ticker));

    const portfolioPriceError = portfolioPrice <= 0;
    const performanceYearsError = lookPastYears < 1;
    const btnDisabled = loading || clearedTickersConfig.length <= 1 || portfolioPriceError || performanceYearsError;

    return (
        <>
            <CardStyled elevation={0}>
                <CardHeader title="Portfolio modelling"/>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <PeriodComponent period={period} onPeriodChange={onPeriodChange}/>
                                    <Tooltip
                                        title="A period will be used to calculate price changes. For instance, if a period is monthly, then price changes will be calculated on a monthly basis and then correlation will be applied."
                                    >
                                        <HelpOutlineIcon color="primary" fontSize="small"/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}
                                      borderColor={theme.palette.grey[300]}
                                      sx={{marginTop: theme.spacing(3), padding: theme.spacing(2)}}>
                                    <TextField
                                        error={portfolioPriceError}
                                        fullWidth
                                        type="number"
                                        value={portfolioPrice}
                                        onChange={e => setPortfolioPrice(e.target.value)}
                                        label="Portfolio Price"
                                        helperText={portfolioPriceError ? "Value should be bigger than 0" : ""}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                    <TextField
                                        error={performanceYearsError}
                                        fullWidth
                                        type="number"
                                        sx={{mt: 2}}
                                        value={lookPastYears}
                                        onChange={e => setLookPastYears(e.target.value)}
                                        label="Look back years"
                                        helperText={portfolioPriceError ? "Minimum value should be 1" : "Calculate performance for past N years"}
                                    />
                                </Grid>
                                <Grid item border={1} borderColor={theme.palette.grey[300]}
                                      sx={{padding: theme.spacing(2)}}>
                                    {tickersConfig.map((item, i) => {
                                        return (
                                            <TickerRow
                                                onChange={handleTickersConfigChange}
                                                value={item.ticker}
                                                position={item.position}
                                                id={i}
                                                weight={item.weight}
                                                tickers={stocks.tickers || []}
                                            />
                                        );
                                    })}
                                </Grid>
                                {
                                    !premium ? ""
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <IconButton color="primary" aria-label="add-ticker-input"
                                                            size="small"
                                                            component="label"
                                                            onClick={addInput}>
                                                    <AddCircleIcon size="small"/>
                                                </IconButton>
                                            </Grid>
                                        </>
                                }
                                <Grid item xs={12}>
                                    <Button variant="contained" disabled={btnDisabled} onClick={modelPortfolio}>
                                        Model
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            loading ?
                                <Grid item xs={10}>
                                    <CircularProgress/>
                                </Grid>
                                :
                                modelingData === null ?
                                    <NoData open={noData} close={() => setNoData(false)}/>
                                    :
                                    <Grid item xs={10}>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Card>
                                                    <CardHeader title={"Correlation matrix"}/>
                                                    <CardContent>
                                                        <CorrelationTable correlationData={modelingData.corr}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            {/*<Grid item>*/}
                                            {/*    <Card>*/}
                                            {/*        <CardHeader title={"Covariance matrix"}/>*/}
                                            {/*        <CardContent>*/}
                                            {/*            <HeatTable data={modelingData.cov} formater={fifthPlaces}/>*/}
                                            {/*        </CardContent>*/}
                                            {/*    </Card>*/}
                                            {/*</Grid>*/}
                                            <Grid item>
                                                <Card>
                                                    <CardHeader title={"Volatility"}/>
                                                    <CardContent>
                                                        <VolatilityComponent volatility={modelingData.vola}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} sx={{width: "100%"}}>
                                                <Card>
                                                    <CardHeader title={"Performance"}/>
                                                    <CardContent>
                                                        <PerformanceComponent portfolioPrice={portfolioPrice}
                                                                              performance={modelingData.performance}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                        }
                    </Grid>
                </CardContent>
            </CardStyled>
            <Portal>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                          open={snackbarOpen}
                          autoHideDuration={6000}
                          onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{width: '100%'}}>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
            </Portal>
        </>
    )
}