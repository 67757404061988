import {useParams} from "react-router-dom";
import {Card, Link, Skeleton, styled, Tab, Tabs} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CompanyOutlook} from "./company_outlook_component";
import {getCompanyOutlook} from "./services/stocks_service";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {Financial} from "./financial";
import {Alert} from "@mui/lab";
import {TA} from "./TA";
import {Peers} from "./peers";
import {AdministrationInnovation} from "./administration_innovation";
import {OverheatingComponent} from "./overheating";
import {TabPanel} from "../utils/tabs_utils";
import {EarningCallComponent} from "./earning_call_transcript";
import KeyExecutivesComponent from "./ExecutivesComponents";
import {CatalystTabComponent} from "./catalyst";
import {hasPremium} from "../service/authService";
import {useSelector} from "react-redux";
import {PriceCmpComponent} from "./PriceCmpComponent";
import {IndicatorsCausationComponent} from "./indicators_causation";


const Item = styled(Card)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body1,
    padding: theme.spacing(3),
    margin: theme.spacing(3),
}));


const Title = styled(Typography)(({theme}) => ({
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    textAlign: 'left',
    fontWeight: 'bold',
}));

const Subtitle = styled(Typography)(({theme}) => ({
    fontSize: '15px'
}));

const TabStyled = styled(Tab)(({theme}) => ({
    margin: theme.spacing(0),
}));


export default function StockDetailsPage(props) {
    let {ticker} = useParams();
    ticker = ticker.toUpperCase();

    const [tabIndex, setTabIndex] = useState(0);
    const [companyOutlook, setCompanyOutlook] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.auth.user);

    const getData = async () => {
        try {
            const {data} = await getCompanyOutlook(ticker);
            setCompanyOutlook(data);
        } catch (err) {
            if (err.response.status === 404) {
                setNotFound(true);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if ((companyOutlook && companyOutlook.ticker === ticker) || notFound) return;
        setLoading(true);
        getData();
    });

    return (
        <Item>
            {
                notFound ? <Alert severity="error">No data found for ticker {ticker}.</Alert>
                    : loading && notFound === false ?
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={window.innerHeight * 0.09}/>
                        :
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                <Title>
                                    <Link
                                        href={companyOutlook.web_site}
                                        target="_blank"
                                        underline="none">
                                        {companyOutlook.company_name}
                                    </Link>
                                    <Subtitle>{ticker}</Subtitle>
                                </Title>
                            </Grid>
                            <Grid item xs={8}>
                                <Tabs
                                    onChange={(event, newValue) => setTabIndex(newValue)}
                                    value={tabIndex}
                                    centered>
                                    <TabStyled label="Company outlook" value={0}/>
                                    <TabStyled label="Financial" value={1}/>
                                    <TabStyled label="Key Executives" value={2}/>
                                    <TabStyled label="TA" value={3}/>
                                    <TabStyled label="Peers" value={4}/>
                                    <TabStyled label="Administration Innovation" value={5}/>
                                    <TabStyled label="Overheating" value={6}/>
                                    <TabStyled label="Earning Call" value={7}/>
                                    {
                                        hasPremium(user) ? <TabStyled label="Catalyst" value={8}/> : ""
                                    }
                                    {
                                        hasPremium(user) ? <TabStyled label="Price Comparison" value={9}/> : ""
                                    }
                                    {
                                        hasPremium(user) ? <TabStyled label="Indicators Causation" value={10}/> : ""
                                    }
                                </Tabs>
                            </Grid>
                            <Grid item xs={12}>
                                <TabPanel value={tabIndex} index={0}>
                                    <CompanyOutlook ticker={ticker} companyOutlook={companyOutlook}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={1}>
                                    <Financial ticker={ticker} companyOutlook={companyOutlook}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={2}>
                                    <KeyExecutivesComponent ticker={ticker} companyName={companyOutlook.company_name}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={3}>
                                    <TA ticker={ticker}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={4}>
                                    <Peers ticker={ticker} />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={5}>
                                    <AdministrationInnovation ticker={ticker}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={6}>
                                    <OverheatingComponent ticker={ticker}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={7}>
                                    <EarningCallComponent ticker={ticker}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={8}>
                                    <CatalystTabComponent ticker={ticker}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={9}>
                                    <PriceCmpComponent ticker={ticker}/>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={10}>
                                    <IndicatorsCausationComponent ticker={ticker}/>
                                </TabPanel>
                            </Grid>
                        </Grid>
            }
        </Item>
    );
}