import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {getCausationAnalysis} from "../idea_generation/brainService";
import {nFormatter} from "../utils/values_formats";
import lightGreen from "@mui/material/colors/lightGreen";

function CorrCauseCard(props) {
    const {title, data} = props;

    const causeArr = data['cause'];

    return <Card>
        <CardHeader title={title}/>
        <CardContent>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Lag</TableCell>
                            <TableCell>Correlation</TableCell>
                            <TableCell>Rolling corr 10y</TableCell>
                            <TableCell>P-Value</TableCell>
                            <TableCell>Predicts?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            causeArr.map(
                                obj => <TableRow
                                    sx={{
                                        backgroundColor: obj["p-value-suggest-prediction"] === true ?
                                            lightGreen[50] :
                                            ""
                                    }}
                                >
                                    <TableCell>{obj["lag"]}</TableCell>
                                    <TableCell>{nFormatter.format(obj["corr"])}</TableCell>
                                    <TableCell>{nFormatter.format(obj["corr_rolling"])}</TableCell>
                                    <TableCell>{nFormatter.format(obj["p-value"])}</TableCell>
                                    <TableCell>{obj["p-value-suggest-prediction"] === true ? "Yes" : "No"}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    </Card>;
}

export function IndicatorsCausationComponent(props) {
    const {ticker} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getCausationAnalysis(ticker);
            setData(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (data === null) getData();
    }, []);

    const dataReady = data !== null;

    return (<Grid container spacing={1}>
        {
            loading ?
                <Grid item xs={12}>
                    <CircularProgress/>
                </Grid> :
                dataReady ? Object.keys(data).map(
                        category =>
                            <>
                                <Grid item xs={12}>
                                    <Divider>{category}</Divider>
                                </Grid>

                                {
                                    Object.keys(data[category]).map(indicator =>
                                        <Grid item>
                                            <CorrCauseCard title={indicator} data={data[category][indicator]}/>
                                        </Grid>
                                    )
                                }
                            </>
                    )
                    : <>No data</>
        }
    </Grid>);
}