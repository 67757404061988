export const validator = (values, fieldName) => {
    let errors = {};
    switch (fieldName) {
        case "email":
            validateEmail(values.email, errors);
            break;
        case "text":
            validateText(values.text, errors);
            break;
        case "name":
            validateName(values.name, errors);
            break;
        default:
    }
    return errors;
};

function validateEmail(email, errors) {
    let result = true;

    if (!email) {
        errors.email = "No return address?";
        result = false;
    } else {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = re.test(String(email).toLowerCase());
        if (!result) errors.email = "Invalid Email address";
    }
    return result;
}

function validateName(name, errors, min_length = 3) {
    let result = true;

    if (!name) {
        errors.name = "How should i call you?";
        result = false;
    } else if (name.length < min_length) {
        errors.name = `Minimum size should be >= ${min_length}`;
        result = false;
    }
    return result;
}

function validateText(text, errors, min_length = 8) {
    let result = true;

    if (!text) {
        errors.text = "No message :'(";
        result = false;
    } else if (text.length < min_length) {
        errors.text = `Minimum message length should be >= ${min_length}`;
        result = false;
    }
    return result;
}
