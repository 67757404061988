import {FETCH_ERROR, FETCH_USER, LOGOUT} from "../types"
import {getUser, hasPremium} from "../../service/authService";

const defaultState = {
    user: getUser(),
    loginError: null,
    hasPremiumAccess: hasPremium(getUser()),
    loading: false
};

export default function (state = defaultState, action) {
    let returnState = state;
    switch (action.type) {
        case FETCH_USER:
            returnState = {
                ...state,
                user: action.payload,
                hasPremiumAccess: hasPremium(getUser()),
                loginError: null,
                loading: false
            }
            break
        case LOGOUT:
            returnState = {
                ...state,
                user: null,
                loginError: null,
                hasPremiumAccess: hasPremium(getUser()),
            }
            break
        case FETCH_ERROR:
            returnState = {
                ...state,
                loginError: action.payload,
                loading: false
            }
            break
    }
    return returnState;
}
