import {styled, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {nFormatter, percentFormatter} from "../utils/values_formats";
import React, {useMemo, useState} from "react";
import {blueGrey} from "@mui/material/colors";
import lightGreen from "@mui/material/colors/lightGreen";

const TableBodyThCell = styled(TableCell)(({theme}) => ({
    fontWeight: 'bold',
    align: 'center',
    textAlign: 'center',
    backgroundColor: blueGrey[100],
}));

const CenteredCell = styled(TableCell)(({theme}) => ({
    align: 'center',
    textAlign: 'center',
}));


function TableEvaluationRow(props) {
    const {
        data, frontCellName, highlight = false, rowClick = (t) => {}
    } = props;

    const formatNumberOrNone = (val) => val !== null ? nFormatter.format(val) : '-'
    const formatPercentOrNone = (val) => val !== null ? percentFormatter.format(val) : '-'

    return <TableRow
        id={frontCellName}
        sx={{'&:hover': {cursor: 'pointer'}, backgroundColor: highlight ? lightGreen[100] : 'transparent'}}
        onClick={() => rowClick(frontCellName)}
    >
        <TableBodyThCell>{frontCellName}</TableBodyThCell>
        <CenteredCell>{formatNumberOrNone(data["pe_curr"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["pe_next"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["peg_curr"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["peg_forward"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["eps_curr"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["eps_next"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["eps_growth_curr"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["eps_growth_forward"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["revenue_growth_curr"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["revenue_growth_forward"])}</CenteredCell>
    </TableRow>
}

function TableHeaderRow(props) {
    const {fierstTHName} = props;

    return <TableRow sx={{backgroundColor: blueGrey[100]}}>
        <CenteredCell>{fierstTHName}</CenteredCell>
        <CenteredCell>PE</CenteredCell>
        <CenteredCell>PE Forward</CenteredCell>
        <CenteredCell>PEG</CenteredCell>
        <CenteredCell>PEG Forward</CenteredCell>
        <CenteredCell>EPS</CenteredCell>
        <CenteredCell>EPS Forward</CenteredCell>
        <CenteredCell>EPS growth</CenteredCell>
        <CenteredCell>EPS growth forward</CenteredCell>
        <CenteredCell>Revenue growth</CenteredCell>
        <CenteredCell>Revenue growth forward</CenteredCell>
    </TableRow>;
}


export function TableEvaluations(props) {
    const {data} = props;
    return <Table sx={{marginTop: 2}} size="small">
        <TableHead>
            <TableHeaderRow firstTHName={""}/>
        </TableHead>
        <TableBody>
            <TableEvaluationRow data={data["average"]} frontCellName={"Average"}/>
            <TableEvaluationRow data={data["weighted"]} frontCellName={"Weighted by MarketCap"}/>
        </TableBody>
    </Table>;
}

function SortedHeaderCell(props) {
    const {sortConfig, handleSort, title, dataKey} = props;

    return <CenteredCell>
        <TableSortLabel
            active={sortConfig.key === dataKey}
            direction={sortConfig.key === dataKey ? sortConfig.direction : 'asc'}
            onClick={() => handleSort(dataKey)}
        >
            {title}
        </TableSortLabel>
    </CenteredCell>
}

export function PremiumSectionTableEvaluations(props) {
    const {data} = props;
    const [sortConfig, setSortConfig] = useState({key: 'ticker', direction: 'asc'});
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (id) => selectedRow === id ? setSelectedRow(null) : setSelectedRow(id);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({
            key,
            direction: isAsc ? 'desc' : 'asc',
        });
    };

    const sortedData = useMemo(() => {
        if (!sortConfig.direction || !sortConfig.key) return data;

        return [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [data, sortConfig]);

    return <Table size="small">
        <TableHead>
            <TableRow sx={{backgroundColor: blueGrey[100]}}>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"Ticker"} dataKey={"ticker"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"PE"} dataKey={"pe_curr"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"PE Forward"}
                                  dataKey={"pe_next"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"PEG"} dataKey={"peg_curr"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"PEG Forward"}
                                  dataKey={"peg_forward"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"EPS"} dataKey={"eps_curr"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"EPS Forward"}
                                  dataKey={"eps_next"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"EPS growth"}
                                  dataKey={"eps_growth_curr"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"EPS growth forward"}
                                  dataKey={"eps_growth_forward"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"Revenue growth"}
                                  dataKey={"revenue_growth_curr"}/>
                <SortedHeaderCell sortConfig={sortConfig} handleSort={handleSort} title={"Revenue growth Forward"}
                                  dataKey={"revenue_growth_forward"}/>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                sortedData.map(d => <TableEvaluationRow data={d} frontCellName={d["ticker"]} rowClick={handleRowClick}
                                                        highlight={selectedRow === d["ticker"]}/>)
            }
        </TableBody>
    </Table>;
}
