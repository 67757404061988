import {
    Card,
    CardContent,
    CardHeader,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {grey} from "@mui/material/colors";

const TableCellNoBorder = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
}));

const TableCellBlackBorder = styled(TableCell)(({theme}) => ({
    borderBottom: "black",
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: grey[300]
    },
}));

export function CommentsComponent(props) {
    const {comments} = props;
    const industriesUniq = new Set(comments.map(item => item["industry"]));
    const industriesLst = Array.from(industriesUniq);

    return (
        <Card>
            <CardHeader title="Comments"/>
            <CardContent>
                <TableContainer>
                    <Table size="small" border={1}>
                        <TableBody>
                            {
                                industriesLst.map(industry => (
                                    <TableRow>
                                        <TableCellBlackBorder>
                                            {industry}
                                        </TableCellBlackBorder>
                                        <TableCellBlackBorder>
                                            <TableContainer>
                                                <Table>
                                                    {
                                                        comments.filter(item => industry === item["industry"]).map(item => (
                                                            <StyledTableRow>
                                                                <TableCellNoBorder sx={{width: item["date"].length}}>
                                                                    {item["date"]}
                                                                </TableCellNoBorder>
                                                                <TableCellNoBorder>
                                                                    {item["comment"]}
                                                                </TableCellNoBorder>
                                                            </StyledTableRow>
                                                        ))
                                                    }
                                                </Table>
                                            </TableContainer>
                                        </TableCellBlackBorder>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>

    );
}