import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Portal,
    Snackbar,
    styled,
    TextField
} from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import {Alert} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";
import {loginReq, logoutReq} from "../store/actions/authActions";


const IconBtnStyled = styled(IconButton)(({theme}) => ({
    marginLeft: theme.spacing(2)
}));

const Btn = styled(Button)(({theme}) => ({
    margin: theme.spacing(2)
}));


export default function AuthComponent(props) {
    const [showFeedback, setShowFeedback] = useState(false);
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState(null);
    const [pwd, setPwd] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(null);
    const [snackbarType, setSnackbarType] = useState("error");
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const userLoginError = useSelector(state => state.auth.loginError);

    useEffect(() => {
        if (!showFeedback) return;

        setShowFeedback(false);
        const userPresent = Boolean(user && user.token);
        if (!userPresent && userLoginError) {
            setSnackbarMsg(userLoginError.detail);
            setSnackbarOpen(true);
            setSnackbarType("error");
        } else if (!userPresent && !userLoginError) {
            setSnackbarMsg("Logged out successfully!");
            setSnackbarOpen(true);
            setSnackbarType("success");
        } else if (userPresent) {
            setSnackbarMsg("Logged in successfully!");
            setSnackbarOpen(true);
            setSnackbarType("success");
            setOpen(false);
        }
    });

    const loginHandle = () => {
        const loggedIn = Boolean(user && user.token);
        if (loggedIn) {
            dispatch(logoutReq()).then(() => setShowFeedback(true));
        } else {
            setOpen(true);
        }
    }

    const loginBtnHandle = () => {
        if (!username || !pwd) {
            setSnackbarMsg("Please fix errors before login!");
            setSnackbarOpen(true);
            setSnackbarType("error");
            return;
        }
        dispatch(loginReq(username, pwd)).then(() => setShowFeedback(true));
    }

    const handleUsernameChange = (e) => {
        const {name, value} = e.target;
        setUsername(value);
    }

    const handlePwdChange = (e) => {
        const {name, value} = e.target;
        setPwd(value);
    }

    return (
        <>
            <IconBtnStyled aria-label="cart" onClick={loginHandle}>
                {
                    user && user.token ? <LoginIcon/> : <LogoutIcon/>
                }
            </IconBtnStyled>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="xs"
            >
                <DialogTitle align="center">Login</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                error={!username}
                                label="Username"
                                name="name"
                                onChange={handleUsernameChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                error={!pwd}
                                label="Password"
                                type="password"
                                name="password"
                                onChange={handlePwdChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Btn fullWidth variant="contained" onClick={loginBtnHandle}>Login</Btn>
                </DialogActions>
            </Dialog>
            <Portal>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}

                          open={snackbarOpen}
                          autoHideDuration={6000}
                          sx={{zIndex: 9999}}
                          onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType} sx={{width: '100%'}}>
                        {snackbarMsg}
                    </Alert>
                </Snackbar>
            </Portal>
        </>
    );
}