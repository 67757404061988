import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    useTheme
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {getTA} from "./services/stocks_service";
import {ReactECharts} from "../utils/echarts_util";
import {percentFormatter} from "../utils/values_formats";
import PeriodToggleGroupComponent, {periodMap} from "../_common/periodComponent";
import {meanTable, statStdTable} from "../_common/ta_components";

const ThTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[100],
    },
    borderBottom: "none",
}));

const ThTableBodyCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.grey[100],
    fontWeight: "bold",
    borderBottom: "none",
}));

const BodyThTableCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.grey[100],
    fontWeight: 'bold',
    borderBottom: "none",
    width: "20%",
}));

const TableCellNoBottomLine = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
}));

export function TA(props) {
    const {ticker} = props;
    const [TAData, setTAData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [period, setPeriod] = useState("d");
    const theme = useTheme();

    const getData = async (period_) => {
        const {data} = await getTA(ticker, period_);
        setTAData(data);
        setPeriod(data.period);
        setLoading(false);
    };

    useEffect(() => {
        if (TAData !== null) return;
        setLoading(true);
        getData(period);
    }, []);

    const binsToPercent = (binsArray) => binsArray.map(item => percentFormatter.format(item));

    const getOption = (data, title) => {
        return {
            title: {
                text: title,
                left: 'center'
            },
            color: [theme.palette.primary.main],
            tooltip: {
                trigger: 'axis'
            },
            xAxis: [
                {
                    type: 'category',
                    data: binsToPercent(data[1]),
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    type: 'bar',
                    data: data[0],
                }
            ]
        }
    }

    const statTable = (data) => {
        const total = data[0].reduce((a, b) => a + b, 0);
        let prevSum = 0;
        const calculatedCumulativeProbability = data[0].map(item => {
            const cumValue = item / total;
            prevSum += cumValue;
            return prevSum;
        });
        return (
            <Table size="small" sx={{border: 1, borderColor: 'grey.400'}}>
                <TableHead>
                    <TableRow>
                        <ThTableCell/>
                        {
                            data[1].map(item => (
                                <ThTableCell
                                    align="center">{percentFormatter.format(item)}</ThTableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <ThTableBodyCell component="th">Num of observations</ThTableBodyCell>
                        {
                            data[0].map(item => (
                                <TableCellNoBottomLine align="center">{item}</TableCellNoBottomLine>
                            ))
                        }
                    </TableRow>
                    <TableRow>
                        <ThTableBodyCell component="th">Cumulative probability %</ThTableBodyCell>
                        {
                            calculatedCumulativeProbability.map(item => (
                                <TableCellNoBottomLine align="center">
                                    {percentFormatter.format(item)}
                                </TableCellNoBottomLine>
                            ))
                        }
                    </TableRow>
                </TableBody>
            </Table>);
    }

    const onPeriodChange = (event) => {
        setLoading(true);
        getData(event.target.value);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        <Grid xs={12}>
                            <PeriodToggleGroupComponent onPeriodChange={onPeriodChange} period={period}/>
                        </Grid>
                        <Grid item xs={6}>
                            {statStdTable(TAData["std_bounds"]["c-c"])}
                            {meanTable(TAData["changes_mean"]["c-c"])}
                        </Grid>
                        <Grid item xs={6}>
                            {statStdTable(TAData["std_bounds"]["h-l"])}
                            {meanTable(TAData["changes_mean"]["h-l"])}
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={getOption(TAData["histogram"]["c-c"], `${periodMap[period]} Close/Close`)}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={getOption(TAData["histogram"]["h-l"], `${periodMap[period]} High-Low`)}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            {statTable(TAData["histogram"]["c-c"])}
                        </Grid>
                        <Grid item xs={6}>
                            {statTable(TAData["histogram"]["h-l"])}
                        </Grid>
                    </>
            }
        </Grid>
    );
}