import React, {useEffect, useState} from "react";
import {getIndustrialProductionData} from "../service/economics";
import {getRecessionAreas} from "../economics_leading/utils";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import Grid from "@mui/material/Unstable_Grid2";

export function IndustrialProductionComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [ip, setIp] = useState([]);

    const getData = async () => {
        setLoading(true);
        const {data} = await getIndustrialProductionData();
        setIp(data);
        setLoading(false);
    };

    useEffect(() => {
        if (ip.length > 0) return;
        getData();
    }, []);

    const dateList = ip.map((item) => {
        return item["row"].split("T")[0];
    });

    const toPercentValue = (d) => d * 100;

    const totalIndex = ip.map((item) => {
        return toPercentValue(item["total_change_1y_ago"]);
    });

    const toOption = (dataList, title) => ({
        animation: false,
        title: {
            text: title,
            subtext: "Change 1y ago",
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: "{value} %"
            }
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(ip)
                },
            },
        ]
    });


    return loading ?
        <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReactECharts option={toOption(totalIndex, "Industrial production: Total Index")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </Grid>
}