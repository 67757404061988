import React, {useEffect, useState} from "react";
import {getEsrData} from "../service/economics";
import {getRecessionAreas} from "../economics_leading/utils";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import Grid from "@mui/material/Unstable_Grid2";

export function EmploymentSituationReportComponent(props) {
    const {topHeight, secondHeight, restHeight} = props;
    const [loading, setLoading] = useState(true);
    const [esr, setEsr] = useState([]);

    const getData = async () => {
        const {data} = await getEsrData();
        setEsr(data);
        setLoading(false);
    };

    useEffect(() => {
        if (esr.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const toPercentValue = (d) => d * 100;

    const dateList = esr.map((item) => {
        return item["row"].split("T")[0];
    });

    const unemploymentRate = esr.map((item) => {
        return item["unemployment_rate"];
    });

    const totalNonfarm = esr.map((item) => {
        return toPercentValue(item["tnonfarm"]);
    });

    const totalPrivate = esr.map((item) => {
        return item["total_private"];
    });

    const totalGoodsProduced = esr.map((item) => {
        return item["goods_produced"];
    });

    const totalServiceProviding = esr.map((item) => {
        return item["service_providing"];
    });

    const totalConstruction = esr.map((item) => {
        return item["constraction"];
    });

    const totalManufacturing = esr.map((item) => {
        return item["manufacturing"];
    });

    const totalFinActivities = esr.map((item) => {
        return item["fin_activities"];
    });

    const totalPABusinessServices = esr.map((item) => {
        return item["professional_and_business_services"];
    });

    const toOption = (dataList, title, subtitle, yAsisProps = {}) => ({
        animation: false,
        title: {
            text: title,
            subtext: subtitle,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            ...yAsisProps
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(esr)
                },
            },
        ]
    });


    return loading ?
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={topHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={secondHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={secondHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={restHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={restHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={restHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={restHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={restHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={restHeight || window.innerHeight * 0.5}/>
            </Grid>
        </Grid>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReactECharts option={toOption(unemploymentRate, "Unemployment Rate")}
                              style={{height: topHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts option={toOption(totalNonfarm, "Total Nonfarm Payrolls", "percent change 1y ago", {
                    axisLabel: {
                        formatter: "{value} %"
                    }
                })} style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts option={toOption(totalPrivate, "Total Private")}
                              style={{height: secondHeight || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(totalGoodsProduced, "Goods-Producing")}
                              style={{height: restHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(totalServiceProviding, "Service-Providing")}
                              style={{height: restHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(totalConstruction, "Construction")}
                              style={{height: restHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(totalManufacturing, "Manufacturing")}
                              style={{height: restHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(totalFinActivities, "Financial Activities")}
                              style={{height: restHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(totalPABusinessServices, "Professional and Business Services")}
                              style={{height: restHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </Grid>
}