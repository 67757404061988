import {tryGetAuthorized} from "./requests";

export const getKansasStressIndexData = () => {
    const url = "/stress/kansas_city";
    return tryGetAuthorized(url);
}

export const getChicagoStressIndexData = () => {
    const url = "/stress/chicago";
    return tryGetAuthorized(url);
}

export const getStLouisIndexData = () => {
    const url = "/stress/st_louis";
    return tryGetAuthorized(url);
}
