import React, {useEffect, useState} from "react";
import {getExecutives, postExecutivesDetails} from "./services/stocks_service";
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogContentText,
    Paper,
    styled
} from "@mui/material";
import {useSelector} from "react-redux";
import {hasPremium} from "../service/authService";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const DialogPaperStyled = styled(Paper)(({theme}) => ({
    minHeight: 640,
    minWidth: 480,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

export default function KeyExecutivesComponent(props) {
    const {ticker, companyName} = props;
    const [loading, setLoading] = useState(true);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [executives, setExecutives] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);
    const [executiveDetails, setExecutiveDetails] = useState({});
    const user = useSelector(state => state.auth.user);
    const isPremium = hasPremium(user);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getExecutives(ticker);
            setExecutives(data);
        } catch (err) {
            const errMsg = err.response.data.detail || err.response.data[0];
            console.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    const getExecutiveDetailsData = async (executiveName, executiveTitle, yearBorn) => {
        try {
            setDialogLoading(true);
            const {data} = await postExecutivesDetails(ticker, companyName, executiveName, executiveTitle, yearBorn);
            setExecutiveDetails({
                executiveName: executiveName,
                executiveTitle: executiveTitle,
                ...data
            });
        } catch (err) {
            const errMsg = err.response.data.detail || err.response.data[0];
            console.error(errMsg);
        } finally {
            setDialogLoading(false);
        }
    };

    useEffect(() => {
        if (executives.length > 0) return;
        getData();
    }, []);

    const handleClickDetails = (executiveName, executiveTitle, yearBorn) => {
        setExecutiveDetails({});
        setOpenDetails(true);
        getExecutiveDetailsData(executiveName, executiveTitle, yearBorn);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        {
                            executives.map(ex => (
                                <Grid item xs={2}>
                                    <Card>
                                        <CardHeader title={ex.name} subheader={ex.title}/>
                                        {
                                            isPremium ?
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        onClick={
                                                            () => handleClickDetails(ex.name, ex.title, ex.year_born)
                                                        }>
                                                        Get AI details
                                                    </Button>
                                                </CardActions>
                                                : ""
                                        }
                                    </Card>
                                </Grid>
                            ))
                        }
                        <Dialog
                            open={openDetails}
                            onClose={() => setOpenDetails(false)}
                            maxWidth={true}
                        >
                            {
                                dialogLoading ?
                                    <DialogPaperStyled>
                                        <CircularProgress/>
                                    </DialogPaperStyled>
                                    :
                                    <Card>
                                        <CardHeader title={executiveDetails.executiveName}
                                                    subheader={executiveDetails.executiveTitle}/>
                                        <CardContent>
                                            <Divider>Biography</Divider>
                                            <DialogContentText>
                                                <Typography variant="body">{executiveDetails.short_bio}</Typography>
                                            </DialogContentText>
                                            <Divider>Experience</Divider>
                                            <DialogContentText>
                                                <Typography
                                                    dangerouslySetInnerHTML={{__html: executiveDetails.experience}}/>
                                            </DialogContentText>
                                        </CardContent>
                                    </Card>
                            }
                        </Dialog>
                    </>
            }
        </Grid>
    );
}