import {getFinancials} from "./services/stocks_service";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {CircularProgress, styled} from "@mui/material";
import Divider from "@mui/material/Divider";
import {ReactECharts} from "../utils/echarts_util";
import {toBarOption} from "./utils";

const DividerStyled = styled(Divider)(({theme}) => ({
    marginBottom: theme.spacing(3),
}));

export function Financial(props) {
    const {ticker} = props;
    const [financialsData, setFinancialsData] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const {data} = await getFinancials(ticker);
        setFinancialsData(data);
        setLoading(false);
    };

    useEffect(() => {
        if (financialsData !== null) return;
        setLoading(true);
        getData();
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <DividerStyled/>
            </Grid>
            {
                financialsData === null ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        <Grid item xs={6}>
                            <ReactECharts option={toBarOption(financialsData["annual_income"], "Eps Y/Y", "epsdiluted")}
                                          style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={toBarOption(financialsData["quarter_income"], "Eps Q/Q", "epsdiluted")}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={toBarOption(financialsData["annual_income"], "Revenue Y/Y", "revenue")}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={toBarOption(financialsData["quarter_income"], "Revenue Q/Q", "revenue")}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={toBarOption(financialsData["annual_income"], "Net income Y/Y", "net_income")}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactECharts
                                option={toBarOption(financialsData["quarter_income"], "Net income Q/Q", "net_income")}
                                style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                        </Grid>
                        { financialsData["dividends"].length > 0 ?
                            <Grid item xs={12}>
                                <ReactECharts
                                    option={toBarOption(financialsData["dividends"], "Dividends", "adj_dividend")}
                                    style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                            </Grid> : ""
                        }
                    </>
            }
        </Grid>
    );
}