import axiosInstance from "../config/http";
import {tryGetAuthorized, tryPostJsonAuthorized} from "../service/requests";

export const getBetaData = (ticker, benchmark, period="d") => {
    return axiosInstance.get(`/calc/beta/${ticker}?b=${benchmark}&p=${period}`);
}

export const getAvailableVsIndicatorsData = () => {
    return axiosInstance.get(`/calc/corr/vsIndicator`);
}

export const postVsIndicatorsData = (indicator, ticker) => {
    return tryPostJsonAuthorized(`/calc/corr/vsIndicator`, {
        "indicator": indicator,
        "ticker": ticker
    });
}

export const postIsmQuadVsIndicatorsData = (industry_name, indicator, ticker) => {
    return tryPostJsonAuthorized(`/calc/corr/quad/vsIsm`, {
        "industry_name": industry_name,
        "indicator": indicator,
        "ticker": ticker
    });
}

export const getIsmQuadVsIndicatorsData = () => {
    return tryGetAuthorized(`/calc/corr/quad/vsIsm`);
}