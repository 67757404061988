import React, {useEffect, useState} from "react";
import {getAAIIInvestorsSentimentData} from "../service/economics";
import Grid from "@mui/material/Unstable_Grid2";
import {Skeleton} from "@mui/material";
import {getRecessionAreas} from "../economics_leading/utils";
import {ReactECharts} from "../utils/echarts_util";
import {green, red} from "@mui/material/colors";

export function AAIIInvestorsSentiment(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [aaii, setAaii] = useState([]);

    const getData = async () => {
        if (loading) {
            const resp = await getAAIIInvestorsSentimentData();
            setAaii(resp.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (aaii.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = aaii.map((item) => {
        return item["row"].split("T")[0];
    });

    const bullish4wMAList = aaii.map((item) => {
        return item["bullish 4 week avg"];
    });

    const bullish12wMAList = aaii.map((item) => {
        return item["bullish 12 week avg"];
    });

    const spread4wMAList = aaii.map((item) => {
        return item["bullish/bearish spread 4 week avg"];
    });

    const spread12wMAList = aaii.map((item) => {
        return item["bullish/bearish spread 12 week avg"];
    });

    const toOption = (data, title) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'line',
            showSymbol: false,
            data: data,
            markArea: {
                data: getRecessionAreas(aaii)
            }
        }
    });

    const toBarOption = (data, title) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
        },
        visualMap: {
            type: 'piecewise',
            dimension: 1,
            show: false,
            min: -1,
            max: 0,
            inRange: {
                color: red[400]
            },
            outOfRange: {
                color: green[400]
            },
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'bar',
            data: data,
        }
    });

    return loading ?
        <>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </>
        :
        <>
            <Grid item xs={6}>
                <ReactECharts
                    option={toOption(bullish4wMAList, "Bullish Index 4 weeks MA")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts
                    option={toOption(bullish12wMAList, "Bullish Index 12 weeks MA")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts
                    option={toBarOption(spread4wMAList, "Bull bear spread 4 week average")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts
                    option={toBarOption(spread12wMAList, "Bull bear spread 12 week average")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </>
        ;
}