import {FETCH_ERROR, FETCH_USER, LOGOUT} from "../types"
import {login, removeUser, setCookieUser} from "../../service/authService";

export const loginReq = (username, password) => async (dispatch) => {
    try {
        const response = await login(username, password);
        setCookieUser(response.data);
        dispatch({type: FETCH_USER, payload: response.data});
    } catch (e) {
        dispatch({
            type: FETCH_ERROR,
            payload: e.response.data,
        });
    }
};

export const logoutReq = () => async (dispatch) => {
    removeUser();
    dispatch({type: LOGOUT});
};
