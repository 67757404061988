import React, {useEffect, useState} from "react";
import {getIndustryInfo} from "./service";
import {
    CardContent,
    CardHeader,
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    Skeleton,
    TableContainer
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {CardDefaultMarginStyled} from "../config/constants";
import Grow from '@mui/material/Grow';
import {PremiumSectionTableEvaluations, TableEvaluations} from "./ValuationTable";
import Divider from "@mui/material/Divider";


function CategoryDetails(props) {
    const {name, data} = props;

    const hasPremium = data !== null && data["premium"] !== undefined

    return data !== null ? <Grow in={true}>
        <CardDefaultMarginStyled>
            <CardHeader title={name}/>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer>
                            <TableEvaluations data={data}/>
                        </TableContainer>
                    </Grid>
                    {
                        hasPremium ?
                            <Grid item xs={12}>
                                <PremiumSection name={name} data={data}/>
                            </Grid>
                            : ""
                    }
                </Grid>
            </CardContent>
        </CardDefaultMarginStyled>
    </Grow> : "";
}

function ListOfClassifications(props) {
    const {open, setOpen, categoryName, categoryValues, setSelectedCategory, setSelectedCategoryInfo} = props;
    return <List dense={true}>
        <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemText primary={categoryName}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} unmountOnExit>
            <List component="div" disablePadding dense={true}>
                {
                    Object.keys(categoryValues).map(s => (
                        <ListItemButton sx={{pl: 4}}
                                        onClick={
                                            () => {
                                                setSelectedCategory(s);
                                                setSelectedCategoryInfo(categoryValues[s]);
                                            }
                                        }>
                            <ListItemText primary={s}/>
                        </ListItemButton>
                    ))
                }
            </List>
        </Collapse>
    </List>;
}

function PremiumSection(props) {
    const {data} = props;
    const val_data = data["premium"]["valuations"] || [];

    return <>
        <Divider>Premium</Divider>
        <PremiumSectionTableEvaluations data={val_data}/>
    </>;
}

export default function Index(props) {
    const {height} = props;
    const [loading, setLoading] = useState(false);

    const [industryData, setIndustryData] = useState({});

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryInfo, setSelectedCategoryInfo] = useState(null);

    const [openGSector, setOpenGSector] = useState(false);
    const [openNSector, setOpenNSector] = useState(false);
    const [openNSubSector, setOpenNSubSector] = useState(false);
    const [openGIndustry, setOpenGIndustry] = useState(false);
    const [openNIndustry, setOpenNIndustry] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getIndustryInfo();
            setIndustryData(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(industryData).length > 0) return;
        getData();
    }, []);

    // влом думать умно зроблю шоб працювало :)

    const dataReady = Object.keys(industryData).length > 0;

    return loading ?
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={9}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </Grid>
        :
        !dataReady ? <>No data. Please return in a few minutes. The service is updating data.</>
            :
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <CardDefaultMarginStyled>
                        <CardHeader title="GICS"/>
                        <CardContent>
                            <ListOfClassifications
                                open={openGSector}
                                setOpen={setOpenGSector}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedCategoryInfo={setSelectedCategoryInfo}
                                categoryName="Sector"
                                categoryValues={industryData["gics"]["sector"]}/>
                            <ListOfClassifications
                                setSelectedCategory={setSelectedCategory}
                                setSelectedCategoryInfo={setSelectedCategoryInfo}
                                open={openGIndustry}
                                setOpen={setOpenGIndustry}
                                categoryName="Industry"
                                categoryValues={industryData["gics"]["industry"]}/>
                        </CardContent>
                    </CardDefaultMarginStyled>
                    <CardDefaultMarginStyled>
                        <CardHeader title="NAICS"/>
                        <CardContent>
                            <ListOfClassifications
                                open={openNSector}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedCategoryInfo={setSelectedCategoryInfo}
                                setOpen={setOpenNSector}
                                categoryName="Sector"
                                categoryValues={industryData["naics"]["sector"]}/>
                            <ListOfClassifications
                                open={openNSubSector}
                                setOpen={setOpenNSubSector}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedCategoryInfo={setSelectedCategoryInfo}
                                categoryName="SubSector"
                                categoryValues={industryData["naics"]["sub_sector"]}/>
                            <ListOfClassifications
                                open={openNIndustry}
                                setOpen={setOpenNIndustry}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedCategoryInfo={setSelectedCategoryInfo}
                                categoryName="Industry"
                                categoryValues={industryData["naics"]["national_industry"]}/>
                        </CardContent>
                    </CardDefaultMarginStyled>
                </Grid>
                <Grid item xs={9}>
                    <CategoryDetails name={selectedCategory} data={selectedCategoryInfo}/>
                </Grid>
            </Grid>
}
