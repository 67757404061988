import axiosInstance from "../config/http";
import {tryGetAuthorized} from "./requests";

export const getNaicsClassification = () => {
    return axiosInstance.get("/naics");
}

export const getIndex = (classification, field, value, years) => {
    return tryGetAuthorized(`/cdetails/${classification}/${field}/${value}?years=${years}`);
}

export const getIndexWithCap = (classification, field, value, years, cap) => {
    return tryGetAuthorized(`/cdetails/${classification}/${field}/${value}/f/${cap}?years=${years}`);
}

export const getGicsClassification = () => {
    return axiosInstance.get("/gics");
}