import React, {useEffect, useState} from "react";
import {getCorporateBonds} from "../service/economics";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "./utils";
import Grid from "@mui/material/Unstable_Grid2";

export default function CorporateBondsComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [bonds, setBonds] = useState([]);

    const getData = async () => {
        const {data} = await getCorporateBonds();
        setBonds(data);
        setLoading(false);
    };

    useEffect(() => {
        if (bonds.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = bonds.map((item) => {
        return item["row"].split("T")[0];
    });

    const aaa_bbb = bonds.map((item) => {
        return item["AAA-BBB"];
    });

    const aaa_ccc = bonds.map((item) => {
        return item["AAA-CCC"];
    });

    const bbb_ccc = bonds.map((item) => {
        return item["BBB-CCC"];
    });

    const tenY_aaa = bonds.map((item) => {
        return item["10y-AAA"];
    });

    const tenY_bbb = bonds.map((item) => {
        return item["10y-BBB"];
    });

    const tenY_CCC = bonds.map((item) => {
        return item["10y-CCC"];
    });

    const toOption = (dataList, title) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value'
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(bonds)
                },
            },
        ]
    });

    return loading ?
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </Grid>
        :
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <ReactECharts option={toOption(aaa_bbb, "AAA-BBB")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(aaa_ccc, "AAA-CCC")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(bbb_ccc, "BBB-CCC")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>

            <Grid item xs={4}>
                <ReactECharts option={toOption(tenY_aaa, "10Y-AAA")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(tenY_bbb, "10Y-BBB")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(tenY_CCC, "10Y-CCC")}
                              style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </Grid>
}