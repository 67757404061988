import axiosInstance from "../config/http";
import Cookies from 'js-cookie';

export const login = (username, password) => {
    return axiosInstance.post("/accounts/d251ff", {
        "username": username,
        "password": password
    });
}

export const logout = () => {
    return axiosInstance.get("/accounts/logout");
}

export const getUser = () => {
    const rawCookie = Cookies.get('user');
    return rawCookie ? JSON.parse(rawCookie) : null;
}

export const removeUser = () => {
    return Cookies.remove('user');
}

export const hasPremium = (user) => {
    return Boolean(user) && (user.is_superuser || user.permission < 500);
}

export const setCookieUser = (user) => {
    return Cookies.set('user', JSON.stringify(user));
}

