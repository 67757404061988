import {styled, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import React from "react";
import {percentFormatter} from "../utils/values_formats";

const TableCellFilled = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.grey[300],
    borderBottom: "none",
}));

const TableCellBorder = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.grey[200],
    borderBottom: 'none',
}));


const TableCellHeader = styled(TableCell)(({theme}) => ({
    fontWeight: "bold",
    borderBottom: "none",
}));

export function HeatTable(props) {
    const {data, lastRow, formater} = props;

    const toTableCell = (item) => {
        return (item === 1 ?
                <TableCellFilled/>
                :
                <TableCellBorder align="center">
                    {formater ? formater(item) : item}
                </TableCellBorder>
        );
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCellHeader component="th"/>
                        {
                            data.columns.map((item) => (
                                <TableCellHeader component="th" align="center">
                                    {item}
                                </TableCellHeader>
                            ))
                        }
                    </TableRow>
                    {
                        data.rows.map((item, i) => (
                            <TableRow>
                                <TableCellHeader component="th" align="right">{item}</TableCellHeader>

                                {
                                    data.m[i].map(toTableCell)
                                }
                            </TableRow>
                        ))
                    }
                    {lastRow}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function CorrelationTable(props) {
    const {correlationData} = props;
    const additionalRow = (<TableRow>
        <TableCellHeader component="th" colspan={correlationData.columns.length} align="right">Portfolio
            correlation:</TableCellHeader>
        <TableCell
            sx={{borderBottom: "none"}}
            component="th"
            align="center">
            {percentFormatter.format(correlationData.avg_t)}
        </TableCell>
    </TableRow>);

    return (
        <HeatTable data={correlationData} lastRow={additionalRow} formater={percentFormatter.format}/>
    );
}
