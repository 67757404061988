import React from "react";
import {Card, CardContent, CardHeader, styled} from "@mui/material";
import CorporateBondsComponent from "./corporateBondsComponent";
import ISMComponent from "./ism";
import RealRatesComponent from "./realRateComponent";
import GovermentBondsComponent from "./govermentBondsComponent";
import Grid from "@mui/material/Unstable_Grid2";
import UMCSIComponent from "./UMCSI";
import HousingComponent from "./housing";
import CommoditiesComponent from "./commodities";
import OECDComponent from "./oecd";
import {CardDefaultMarginStyled} from "../config/constants";
import NfibComponent from "./nfib";

const GreyCardStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[100]
}));

export default function LeadingIndicatorsPage() {
    return (
        <CardDefaultMarginStyled elevation={0}>
            <CardHeader title="Leading indicators"/>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <CardDefaultMarginStyled>
                            <CardHeader title="Real rates"/>
                            <CardContent>
                                <RealRatesComponent height={window.innerHeight * 0.3}/>
                            </CardContent>
                        </CardDefaultMarginStyled>
                    </Grid>
                    <Grid item xs={7}>
                        <CardDefaultMarginStyled>
                            <CardHeader title="US treasuries spread"/>
                            <CardContent>
                                <GovermentBondsComponent height={window.innerHeight * 0.3}/>
                            </CardContent>
                        </CardDefaultMarginStyled>
                    </Grid>
                </Grid>
                <GreyCardStyled>
                    <CardHeader title="Corporate credit spreads"/>
                    <CardContent>
                        <CorporateBondsComponent height={window.innerHeight * 0.3}/>
                    </CardContent>
                </GreyCardStyled>
                <CardDefaultMarginStyled>
                    <CardHeader title="ISM"/>
                    <CardContent>
                        <ISMComponent height={window.innerHeight * 0.4} secondHeight={window.innerHeight * 0.3}/>
                    </CardContent>
                </CardDefaultMarginStyled>
                <GreyCardStyled>
                    <CardHeader title="University of Michigan"/>
                    <CardContent>
                        <UMCSIComponent height={window.innerHeight * 0.3}/>
                    </CardContent>
                </GreyCardStyled>
                <CardDefaultMarginStyled>
                    <CardHeader title="Building permits"/>
                    <CardContent>
                        <HousingComponent height={window.innerHeight * 0.3}/>
                    </CardContent>
                </CardDefaultMarginStyled>
                <GreyCardStyled>
                    <CardHeader title="NFIB: Small Business"/>
                    <CardContent>
                        <NfibComponent height={window.innerHeight * 0.3}/>
                    </CardContent>
                </GreyCardStyled>
                <CardDefaultMarginStyled>
                    <CardHeader title="Commodities"/>
                    <CardContent>
                        <CommoditiesComponent height={window.innerHeight * 0.3}/>
                    </CardContent>
                </CardDefaultMarginStyled>
                <GreyCardStyled>
                    <CardHeader title="OECD"/>
                    <CardContent>
                        <OECDComponent height={window.innerHeight * 0.3}/>
                    </CardContent>
                </GreyCardStyled>
            </CardContent>
        </CardDefaultMarginStyled>
    );
}