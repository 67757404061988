import {getUser} from "./authService";
import axiosInstance from "../config/http";

export const tryGetAuthorized = (url) => {
    const user = getUser();
    if (user && user.token) {
        return axiosInstance.get(url, {
            headers: {
                'Authorization': `Token ${user.token}`
            }
        });
    }
    return axiosInstance.get(url)
}

export const tryPostJsonAuthorized = (url, data) => {
    const user = getUser();
    if (user && user.token) {
        return axiosInstance.post(url, data, {
            headers: {
                'Authorization': `Token ${user.token}`,
                'Content-type': 'application/json'
            }
        });
    }
    return axiosInstance.post(url, data, {
        headers: {
            'Content-type': 'application/json'
        }
    });
}