import React, {useEffect, useState} from "react";
import {getDurableGoodsData} from "../service/economics";
import {getRecessionAreas} from "../economics_leading/utils";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import Grid from "@mui/material/Unstable_Grid2";

export function DurableGoodsComponent(props) {
    const {height, topHeight} = props;
    const [loading, setLoading] = useState(true);
    const [durableGoods, setDurableGoods] = useState([]);

    const getData = async () => {
        const {data} = await getDurableGoodsData();
        setDurableGoods(data);
        setLoading(false);
    };

    useEffect(() => {
        if (durableGoods.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = durableGoods.map((item) => {
        return item["row"].split("T")[0];
    });

    const newOrders = durableGoods.map((item) => {
        return item["new_orders"];
    });

    const exTransportation = durableGoods.map((item) => {
        return item["new_orders_ex_tr"];
    });

    const exDefence = durableGoods.map((item) => {
        return item["new_orders_durable_goods_ex_defence"];
    });

    const nondefenceExAicraft = durableGoods.map((item) => {
        return item["new_orders_nondef_ex_aicraft"];
    });

    const transportation = durableGoods.map((item) => {
        return item["new_orders_transp"];
    });

    const fabricatedMetalProducts = durableGoods.map((item) => {
        return item["new_orders_fabricated_metal_products"];
    });

    const machinery = durableGoods.map((item) => {
        return item["new_orders_machinery"];
    });

    const computers = durableGoods.map((item) => {
        return item["new_orders_computers"];
    });

    const metals = durableGoods.map((item) => {
        return item["new_primary_metals"];
    });

    const equipment = durableGoods.map((item) => {
        return item["new_electrical_equipment"];
    });

    const toOption = (dataList, title) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(durableGoods)
                },
            },
        ]
    });

    return loading ?
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={topHeight || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>

            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>

            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>

            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </Grid>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReactECharts option={toOption(newOrders, "New orders")}
                              style={{height: topHeight || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(exTransportation, "New Orders: excluding transportation")}
                              style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(exDefence, "New Orders: excluding defence")}
                              style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(nondefenceExAicraft, "New Orders: nondefense excluding aircraft")}
                              style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(transportation, "New Orders: transportation")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(fabricatedMetalProducts, "New Orders: fabricated metal products")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(machinery, "New Orders: machinery")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(computers, "New Orders: computers")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(metals, "New Orders: metals")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts option={toOption(equipment, "New Orders: equipment")}
                              style={{height: height || window.innerHeight * 0.3, width: "100%"}}/>
            </Grid>
        </Grid>
}