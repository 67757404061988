import {getEarningCallData} from "./services/stocks_service";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    useTheme
} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import Typography from "@mui/material/Typography";
import {nFormatter} from "../utils/values_formats";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {hasPremium} from "../service/authService";
import {getAnalysis} from "./ai/ai_service";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import {deepOrange} from "@mui/material/colors";

const ListIdeas = (props) => {
    const {data, title} = props;

    return <Card>
        <CardHeader title={title}/>
        <CardContent>
            <List dense={true}>
                {
                    data.length > 0 ? data.map(d => <>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon sx={{color: deepOrange[100]}}/>
                            </ListItemIcon>
                            <ListItemText primary={d}/>
                        </ListItem>
                        <Divider variant="inset" component="li"/>
                    </>) : <></>
                }
            </List>
        </CardContent>
    </Card>
}

function AIComponent(props) {
    const {ticker} = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getAnalysis(ticker);
            setData(data);
        } finally {
            setLoading(false);
        }
    };

    const isDataReady = data !== null

    if (isDataReady && !data['parsed']) {
        return <Typography>{data['raw']}</Typography>
    }

    return <>
        {
            loading ? <CircularProgress/> :
                !isDataReady ?
                    <Button variant="contained" onClick={getData} disabled={loading}>
                        Parse with AI
                    </Button> :
                    <Grid container spacing={2} sx={{marginBottom: 5}}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Sentiment:</Typography>
                            <Typography variant="body">{data['parsed']['sentiment']}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            <ListIdeas title={"Positives"} data={data['parsed']["positive"]}/>
                        </Grid>
                        <Grid xs={4}>
                            <ListIdeas title={"Negatives"} data={data['parsed']["negative"]}/>
                        </Grid>
                        <Grid xs={4}>
                            <ListIdeas title={"Future plans"} data={data['parsed']["future_plans"]}/>
                        </Grid>
                        <Grid xs={6}>
                            <ListIdeas title={"Sector"} data={data['parsed']["sector"]}/>
                        </Grid>
                        <Grid xs={6}>
                            <ListIdeas title={"Industry"} data={data['parsed']["industry"]}/>
                        </Grid>
                    </Grid>
        }
    </>
}


const SentimentScore = (props) => {
    const {score} = props;
    const theme = useTheme();
    const isScorePositive = score >= 0.5;

    return (
        <Card elevation={0}>
            <CardContent>
                <Typography variant="h6">
                    Sentiment score
                </Typography>
                {
                    isScorePositive ?
                        <ArrowDropUpIcon sx={{fontSize: 100}} color="success"/>
                        :
                        <ArrowDropDownIcon sx={{fontSize: 100}} color="error"/>
                }
                <Typography variant="h6" sx={{
                    color: isScorePositive ? theme.palette.success.main : theme.palette.error.main
                }}>
                    {nFormatter.format(score)}
                </Typography>
            </CardContent>
        </Card>
    )
        ;
};

export function EarningCallComponent(props) {
    const {ticker} = props;
    const [loading, setLoading] = useState(true);
    const [ec, setEc] = useState({});
    const user = useSelector(state => state.auth.user);
    const isPremium = hasPremium(user);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getEarningCallData(ticker);
            setEc(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(ec).length > 0) return;
        getData();
    }, []);

    const sentimentsCnt = () => {
        let res = [];
        Object.keys(ec.sentiment).filter(item => item !== "score").forEach(key => {
            res.push({value: ec.sentiment[key], name: key});
        });
        return res;
    };

    const toOption = (data, title) => ({
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                type: 'pie',
                radius: '50%',
                data: data,
            }
        ]
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        <Grid item xs={12}>
                            <Paper elevation={0} sx={{m: 0, p: 0}}>
                                <Grid container>
                                    <Grid item xs={5} display="flex" justifyContent="center" alignItems="center">
                                        <SentimentScore score={ec.sentiment.score}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReactECharts option={toOption(sentimentsCnt(), "Sentiment")}
                                                      style={{height: window.innerHeight * 0.3, width: "100%"}}/>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="right" alignItems="right">
                                        <Link
                                            href="https://github.com/imnileshd/nlp-sentiment-analysis/tree/master"
                                            target="blank">Reference</Link>
                                    </Grid>
                                    {
                                        isPremium ? <>
                                            <Grid item xs={12}>
                                                <Divider>Premium Section</Divider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AIComponent ticker={ticker}/>
                                            </Grid>
                                        </> : <></>
                                    }
                                    <Grid item xs={12}>
                                        <Divider>
                                            Transcript
                                        </Divider>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        {
                            ec.transcript.split("\n").map(speaker => (
                                <Grid item xs={12} display="flex" justifyContent="left" alignItems="left"
                                      textAlign="left">
                                    <Typography variant="body1" gutterBottom>
                                        <div style={{fontWeight: 'bold'}}>{speaker.split(":")[0]}:</div>
                                        {speaker.split(":")[1]}
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </>
            }
        </Grid>
    );
}