import React, {useEffect, useState} from "react";
import {getSimplifiedWinLosTickers} from "../idea_generation/brainService";
import Grid from "@mui/material/Unstable_Grid2";
import {List, ListItem, ListItemButton, ListItemText, ListSubheader} from "@mui/material";

export function CrystalBall(props) {
    const {height} = props;
    const [loading, setLoading] = useState(false);
    const [winLosData, setWinLosData] = useState({});

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getSimplifiedWinLosTickers();
            setWinLosData(response.data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(
        () => {
            fetchData()
        }, []
    );

    const dataReady = loading === false && Object.keys(winLosData).length > 0;

    return <Grid container>
        <Grid item xs={6}>
            <List dense={true}
                  subheader={
                      <ListSubheader component="div" id="ticker-winners" sx={{color: "green"}}>
                          Outperformers
                      </ListSubheader>
                  }
            >
                {
                    dataReady ? winLosData["winners"].map(
                            ticker => <ListItem>
                                <ListItemButton  href={`/company/${ticker}`} target="blank">
                                    <ListItemText primary={ticker} sx={{textAlign: "center"}}/>
                                </ListItemButton>
                            </ListItem>
                        )
                        : ""
                }
            </List>
        </Grid>
        <Grid item xs={6}>
            <List dense={true}
                  subheader={
                      <ListSubheader component="div" id="ticker-losers" sx={{color: "red"}}>
                          laggers
                      </ListSubheader>
                  }
            >
                {
                    dataReady ? winLosData["losers"].map(
                            ticker => <ListItem>
                                <ListItemButton href={`/company/${ticker}`} target="blank">
                                    <ListItemText primary={ticker} sx={{textAlign: "center"}}/>
                                </ListItemButton>
                            </ListItem>
                        )
                        : ""
                }
            </List>
        </Grid>
    </Grid>
}