import {getAdministrationInnovation} from "./services/stocks_service";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {CircularProgress, styled} from "@mui/material";
import Divider from "@mui/material/Divider";
import {ReactECharts} from "../utils/echarts_util";
import {toBarOption} from "./utils";

const DividerStyled = styled(Divider)(({theme}) => ({
    marginBottom: theme.spacing(3),
}));

export function AdministrationInnovation(props) {
    const {ticker} = props;
    const [admInn, setAdmInn] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const {data} = await getAdministrationInnovation(ticker);
        setAdmInn(data);
        setLoading(false);
    };

    useEffect(() => {
        if (admInn !== null) return;
        setLoading(true);
        getData();
    }, []);

    const yAsisProps = {
        axisLabel: {
            formatter: "{value} %"
        }
    }

    const toPercentValue = (d) => Math.round(d * 100);

    return (<Grid container>
        <Grid item xs={12}>
            <DividerStyled/>
        </Grid>
        {admInn === null ? <Grid item xs={12}>

            <CircularProgress/>
        </Grid> : <>
            <Grid item xs={6}>
                <ReactECharts
                    option={toBarOption(admInn["annual"], "Innovations/Revenue Y/Y", "innovation", toPercentValue, yAsisProps)}
                    style={{height: window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts
                    option={toBarOption(admInn["quarter"], "Innovations/Revenue Q/Q", "innovation", toPercentValue, yAsisProps)}
                    style={{height: window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts
                    option={toBarOption(admInn["annual"], "Administration/Revenue Y/Y", "administration", toPercentValue, yAsisProps)}
                    style={{height: window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={6}>
                <ReactECharts
                    option={toBarOption(admInn["quarter"], "Administration/Revenue Q/Q", "administration", toPercentValue, yAsisProps)}
                    style={{height: window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </>}
    </Grid>);
}