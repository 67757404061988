import React, {useEffect, useState} from "react";
import {Card, CardContent, CardHeader, Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getISMData} from "../service/economics";
import {getRecessionAreas} from "./utils";
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";

export default function ISMComponent(props) {
    const {height, secondHeight} = props;
    const [loading, setLoading] = useState(true);
    const [ISM, setISM] = useState([]);

    const getData = async () => {
        const {data} = await getISMData();
        setISM(data);
        setLoading(false);
    };

    useEffect(() => {
        if (ISM.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const PMI = ISM.filter(item => item["report name"] === "PMI");
    const NMI = ISM.filter(item => item["report name"] === "NMI");

    const pmiRecessionData = PMI.filter(x => x["name"] === "Main");
    const nmiRecessionData = NMI.filter(x => x["name"] === "Main");

    const dateListPmi = PMI.filter(x => x["name"] === "Main").map((item) => {
        return item["row"].split("T")[0];
    });
    const dateListNmi = NMI.filter(x => x["name"] === "Main").map((item) => {
        return item["row"].split("T")[0];
    });

    const pmiMainList = PMI.filter(x => x["name"] === "Main").map((item) => {
        return item["index"];
    });
    const nmiMainList = NMI.filter(x => x["name"] === "Main").map((item) => {
        return item["index"];
    });

    const pmiNewOrdersList = PMI.filter(x => x["name"] === "New Orders").map((item) => {
        return item["index"];
    });
    const nmiNewOrdersList = NMI.filter(x => x["name"] === "New Orders").map((item) => {
        return item["index"];
    });

    const pmiProductionList = PMI.filter(x => x["name"] === "Production").map((item) => {
        return item["index"];
    });
    const nmiBAList = NMI.filter(x => x["name"] === "Business Activity").map((item) => {
        return item["index"];
    });

    const pmiInventoriesList = PMI.filter(x => x["name"] === "Inventories").map((item) => {
        return item["index"];
    });
    const nmiInventoriesList = NMI.filter(x => x["name"] === "Inventories").map((item) => {
        return item["index"];
    });

    const toOption = (dataList, dateList, title, recessionData) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList,
        },
        yAxis: {
            type: 'value',
            min: Math.round(Math.min(...dataList) - 10),
            max: Math.round(Math.max(...dataList) + 10),
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                data: dataList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(recessionData)
                },
            },
        ]
    });

    return loading ?
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </Grid>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card elevation={1}>
                    <CardHeader title="Manufacturing"/>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ReactECharts
                                    option={toOption(pmiMainList, dateListPmi, "Main Index", pmiRecessionData)}
                                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <ReactECharts
                                    option={toOption(pmiNewOrdersList, dateListPmi, "New Orders", pmiRecessionData)}
                                    style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <ReactECharts
                                    option={toOption(pmiProductionList, dateListPmi, "Production", pmiRecessionData)}
                                    style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <ReactECharts
                                    option={toOption(pmiInventoriesList, dateListPmi, "Inventories", pmiRecessionData)}
                                    style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Card elevation={1}>
                    <CardHeader title="Services"/>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ReactECharts
                                    option={toOption(nmiMainList, dateListNmi, "Main Index", nmiRecessionData)}
                                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <ReactECharts
                                    option={toOption(nmiNewOrdersList, dateListNmi, "New Orders", nmiRecessionData)}
                                    style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <ReactECharts
                                    option={toOption(nmiBAList, dateListNmi, "Business Activity", nmiRecessionData)}
                                    style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <ReactECharts
                                    option={toOption(nmiInventoriesList, dateListNmi, "Inventories", nmiRecessionData)}
                                    style={{height: secondHeight || window.innerHeight * 0.4, width: "100%"}}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
}