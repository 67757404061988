import React, {useEffect, useState} from "react";
import {getGovermentalBonds} from "../service/economics";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "./utils";

export default function GovermentBondsComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [bonds, setBonds] = useState([]);

    const getData = async () => {
        const {data} = await getGovermentalBonds();
        setBonds(data);
        setLoading(false);
    };

    useEffect(() => {
        if (bonds.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = bonds.map((item) => item["row"].split("T")[0]);
    const aValueList = bonds.map((item) => item["2y"]);
    const bValueList = bonds.map((item) => item["3m"]);

    const option = {
        animation: false,
        title: {
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Spread 10Y-2Y', 'Spread 10Y-3M'],
            left: 'left',
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value'
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                name: 'Spread 10Y-2Y',
                data: aValueList,
                showSymbol: false,
                markArea: {
                    data: getRecessionAreas(bonds)
                },
            },
            {
                type: 'line',
                name: 'Spread 10Y-3M',
                showSymbol: false,
                data: bValueList,
            },
        ]
    };

    return loading ?
        <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
        :
        <ReactECharts option={option} style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>;
}