import React, {useEffect, useState} from "react";
import {getOECDData} from "../service/economics";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "./utils";

export default function OECDComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [OECD, setOECD] = useState([]);

    const getData = async () => {
        setLoading(true);
        const {data} = await getOECDData();
        setOECD(data);
        setLoading(false);
    };

    useEffect(() => {
        if (OECD.length > 0) return;
        getData();
    }, []);

    const dateList = OECD.map((item) => {
        return item["row"].split("T")[0];
    });

    const valueList = OECD.map((item) => {
        return item["value"];
    });

    const option = {
        animation: false,
        title: {
            text: "Composite leading indicator",
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            min: Math.round(Math.min(...valueList) - 10),
            max: Math.round(Math.max(...valueList) + 10),
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'line',
            showSymbol: false,
            data: valueList,
            markArea: {
                data: getRecessionAreas(OECD)
            }
        }
    };

    return loading ?
        <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
        :
        <ReactECharts option={option} style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>;
}