import React from "react";

export const toBarOption = (data, title, key, applyValueTransformer = null, yAsisProps = {}) => {
    const dateList = data.map(function (item) {
        return item["date"];
    });

    const dataList = data.map(function (item) {
        return applyValueTransformer !== null ? applyValueTransformer(item[key]) : item[key];
    });

    return {
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: dateList.reverse()
        },
        yAxis: {
            type: 'value',
            ...yAsisProps,
        },
        series: [
            {
                data: dataList.reverse(),
                type: 'bar',
            }
        ]
    }
};