import {styled, Table, TableBody, TableCell, TableContainer, TableRow, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import {AnalystsSectionComponent, SurprisesSection, Valuations, ValuationsDebtSection} from "./outlook_financial";
import {dollarFormatter, nFormatter, percentFormatter} from "../utils/values_formats";
import {useSelector} from "react-redux";
import {hasPremium} from "../service/authService";
import {KPIComponent} from "./ai/kpi";
import {green, red} from "@mui/material/colors";
import {ReactECharts} from "../utils/echarts_util";


const DividerBottomStyled = styled(Divider)(({theme}) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

const DividerStyled = styled(Divider)(({theme}) => ({
    marginBottom: theme.spacing(3),
}));

const TableBodyStyled = styled(TableBody)(({theme}) => ({
    backgroundColor: theme.palette.grey[100]
}));


const TableBodyThCell = styled(TableCell)(({theme}) => ({
    fontWeight: 'bold'
}));


export function CompanyOutlook(props) {
    const {companyOutlook, ticker} = props;
    const user = useSelector(state => state.auth.user);
    const isPremium = hasPremium(user);
    const theme = useTheme();

    const cellCmp = (title, value, formatter, nullable = false, td_sx = null) => {
        let preparedValue = formatter.format(value);
        if (value == null) {
            preparedValue = "-";
        }

        if (nullable && (value == null || value === 0)) {
            preparedValue = "-";
        }

        return (
            <>
                <TableBodyThCell component="th" scope="row">
                    {title}
                </TableBodyThCell>
                <TableCell component="td" scope="row" sx={td_sx || {}}>
                    {preparedValue}
                </TableCell>
            </>
        )
    }

    const toData = (values) => {
        let res = [];
        if (!values) {
            return res;
        }
        for (const [key, value] of Object.entries(values)) {
            res.push({value: value, name: key});
        }
        return res;
    }

    const resolveOption = (data, title) => ({
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                type: 'pie',
                radius: '50%',
                data: toData(data),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });

    const IsNotNullOrUndefined = (el) => el !== null && el !== undefined;

    const optionSalesPerBusiness = resolveOption(companyOutlook.sales_per_business, "Sales per Business");
    const optionSalesPerRegion = resolveOption(companyOutlook.sales_per_region, "Sales per region");
    let margin_of_safety = null;
    if (IsNotNullOrUndefined(companyOutlook.price) && IsNotNullOrUndefined(companyOutlook.price_target)) {
        margin_of_safety = (companyOutlook.price_target - companyOutlook.price) / companyOutlook.price_target;
    }
    const bgColor = margin_of_safety >= 0 ? green[400] : margin_of_safety !== null ? red[400] : null

    return (
        <Grid container display="flex" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <DividerStyled/>
            </Grid>
            <Grid item xs={9}>
                <TableContainer>
                    <Table>
                        <TableBodyStyled>
                            <TableRow>
                                {cellCmp("Last price", companyOutlook.price, dollarFormatter)}
                                {cellCmp("Target price", companyOutlook.price_target, dollarFormatter)}
                                {cellCmp("Margin of safety", margin_of_safety, percentFormatter, true, {color: bgColor})}
                            </TableRow>
                            <TableRow>
                                {cellCmp("Market cap", companyOutlook.market_capitalization, nFormatter)}
                                {cellCmp("P/E", companyOutlook.pe, nFormatter)}
                                {cellCmp("Quick ratio", companyOutlook.quick_ratio, nFormatter)}
                                {cellCmp("Short ratio", companyOutlook.short.ratio, nFormatter)}
                                {cellCmp("Revenue growth Y/Y", companyOutlook.revenue_growth_yoy_ttm, percentFormatter, true)}
                                {cellCmp("EPS growth Y/Y", companyOutlook.eps_growth_yoy_ttm, percentFormatter, true)}

                                {cellCmp("EBITDA margin", companyOutlook.ebitda_margin, percentFormatter, true)}
                            </TableRow>
                            <TableRow>
                                {cellCmp("Volume", companyOutlook.avg_volume, nFormatter)}
                                {cellCmp("PEG", companyOutlook.peg, nFormatter)}
                                {cellCmp("Current ratio", companyOutlook.current_ratio, nFormatter)}
                                {cellCmp("Short % of flow", companyOutlook.short.percent_of_float, percentFormatter)}
                                {cellCmp("Revenue growth Q/Q", companyOutlook.revenue_growth_qoq_ttm, percentFormatter, true)}
                                {cellCmp("EPS growth Q/Q", companyOutlook.eps_growth_qoq_ttm, percentFormatter, true)}
                                {cellCmp("EV/EBIT", companyOutlook.ev_ebit, nFormatter)}
                            </TableRow>
                            <TableRow>
                                {cellCmp("Enterprise value", companyOutlook.ev, nFormatter)}
                                {cellCmp("Dividend yield", companyOutlook.dividend_yield, percentFormatter, true)}
                                {cellCmp("ROA", companyOutlook.roa, percentFormatter)}
                                {cellCmp("Price/Book", companyOutlook.price_to_book, nFormatter)}
                                {cellCmp("Net revenue margin", companyOutlook.net_margin, percentFormatter, true)}
                                {cellCmp("EBIT margin", companyOutlook.ebit_margin, percentFormatter, true)}
                                {cellCmp("EV/EBITDA", companyOutlook.ev_ebitda, nFormatter)}
                            </TableRow>
                            <TableRow>
                                {cellCmp("Beta 5Y", companyOutlook.beta, nFormatter)}
                                {cellCmp("Dividend", companyOutlook.latest_dividend, dollarFormatter)}
                                {cellCmp("ROE", companyOutlook.roe, percentFormatter)}
                                {cellCmp("R&D ratio", companyOutlook.rnd_ratio, nFormatter)}
                                {cellCmp("Mrk Cap / R&D ratio", companyOutlook.mrk_cap_to_rnd_ratio, nFormatter)}
                                {cellCmp("Working Capital", companyOutlook.working_capital, nFormatter)}
                                {cellCmp("Working Capital / Total Assets", companyOutlook.working_capital_to_total_assets, nFormatter)}
                            </TableRow>
                        </TableBodyStyled>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <DividerStyled/>
            </Grid>
            <Grid item xs={10} sx={{marginLeft: theme.spacing(26)}}>
                <Grid container spacing={10}>
                    <Grid item>
                        <Valuations fin={companyOutlook.fin}/>
                    </Grid>
                    <Grid item xs={12}>
                        <AnalystsSectionComponent ticker={ticker}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={10} sx={{marginLeft: theme.spacing(26)}}>
                <Grid container spacing={10}>
                    <Grid item>
                        <ValuationsDebtSection fin={companyOutlook.fin}/>
                    </Grid>
                    <Grid item>
                        {companyOutlook.surprises.length > 0 ?
                            <SurprisesSection surprises={companyOutlook.surprises}/> : ""}
                    </Grid>
                </Grid>
            </Grid>
            {
                (companyOutlook.sales_per_business || companyOutlook.sales_per_region) ?
                    <>
                        <Grid item xs={12}>
                            <DividerStyled/>
                        </Grid>
                        {
                            companyOutlook.sales_per_business ?
                                <Grid item xs={6}>
                                    <ReactECharts option={optionSalesPerBusiness}
                                                  style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                                </Grid>
                                : ""
                        }
                        {
                            companyOutlook.sales_per_region ?
                                <Grid item xs={6}>
                                    <ReactECharts option={optionSalesPerRegion}
                                                  style={{height: window.innerHeight * 0.5, width: "100%"}}/>
                                </Grid>
                                : ""
                        }
                    </>
                    : ""
            }
            {
                isPremium ?
                    <>
                        <Grid item xs={12}>
                            <Divider sx={{opacity: 0.6}}>
                                KPI
                            </Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <KPIComponent ticker={ticker}/>
                        </Grid>
                    </>
                    : ""
            }
            <Grid item xs={12}>
                <DividerBottomStyled sx={{opacity: 0.6}}>
                    Description
                </DividerBottomStyled>
                <Typography variant="body2">
                    {companyOutlook.description}
                </Typography>
            </Grid>
        </Grid>
    );
}