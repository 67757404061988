import {tryGetAuthorized} from "./requests";

export const getRealRates = () => {
    const url = "/bungalo/real-rates";
    return tryGetAuthorized(url);
}

export const getGovermentalBonds = () => {
    const url = "/bungalo/govermental/bonds";
    return tryGetAuthorized(url);
}

export const getCorporateBonds = () => {
    const url = "/bungalo/corporate/bonds";
    return tryGetAuthorized(url);
}

export const getISMData = () => {
    const url = "/bungalo/ism";
    return tryGetAuthorized(url);
}

export const getUMCSIMainData = () => {
    const url = "/bungalo/csi";
    return tryGetAuthorized(url);
}

export const getHousingAuthorizedData = () => {
    const url = "/bungalo/housing";
    return tryGetAuthorized(url);
}

export const getNfibData = () => {
    const url = "/bungalo/nfib";
    return tryGetAuthorized(url);
}

export const getCommoditiesData = () => {
    const url = "/bungalo/commodities";
    return tryGetAuthorized(url);
}

export const getFuturesData = () => {
    const url = "/bungalo/fut";
    return tryGetAuthorized(url);
}

export const getCommoditiesCOTData = () => {
    const url = "/bungalo/investors/cot/commodities";
    return tryGetAuthorized(url);
}

export const getFinancialsCOTData = () => {
    const url = "/bungalo/investors/cot/fin";
    return tryGetAuthorized(url);
}

export const getEsrData = () => {
    const url = "/bungalo/esr";
    return tryGetAuthorized(url);
}

export const getInflationData = () => {
    const url = "/bungalo/infl";
    return tryGetAuthorized(url);
}

export const getIndustrialProductionData = () => {
    const url = "/bungalo/ip";
    return tryGetAuthorized(url);
}

export const getFedData = () => {
    const url = "/bungalo/fed";
    return tryGetAuthorized(url);
}

export const getRealGDPData = () => {
    const url = "/bungalo/rgdp";
    return tryGetAuthorized(url);
}

export const getAAIIInvestorsSentimentData = () => {
    const url = "/bungalo/investors/sentiment";
    return tryGetAuthorized(url);
}

export const getSpeculationsData = () => {
    const url = "/bungalo/speculations";
    return tryGetAuthorized(url);
}

export const getDurableGoodsData = () => {
    const url = "/bungalo/dg";
    return tryGetAuthorized(url);
}

export const getOECDData = () => {
    const url = "/bungalo/oecd";
    return tryGetAuthorized(url);
}