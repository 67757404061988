import {FETCH_ERROR, FETCH_STOCKS} from "../types"
import {listStocks} from "../../StockPage/services/stocks_service";

export const stocksReq = () => async (dispatch) => {
    try {
        const response = await listStocks();
        dispatch({type: FETCH_STOCKS, payload: response.data});
    } catch (e) {
        dispatch({
            type: FETCH_ERROR,
            payload: e.response.data,
        });
    }
};
