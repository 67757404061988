import {getOverheat} from "./services/stocks_service";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {CircularProgress, styled} from "@mui/material";
import Divider from "@mui/material/Divider";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "../economics_leading/utils";
import Typography from "@mui/material/Typography";

const DividerStyled = styled(Divider)(({theme}) => ({
    marginBottom: theme.spacing(3),
}));

export function OverheatingComponent(props) {
    const {ticker, height} = props;
    const [overheating, setOverheating] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const {data} = await getOverheat(ticker);
        setOverheating(data);
        setLoading(false);
    };

    useEffect(() => {
        if (overheating.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = overheating.map(item => {
        return item["row"].split("T")[0];
    });

    const aValueList = overheating.map(item => {
        return item["close_change"];
    });

    const bValueList = overheating.map(item => {
        return item["revenue_change"];
    });

    const option = {
        title: {
            text: "Revenue vs Close Q/Q",
            subtext: "value changes",
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Close Price Change', 'Revenue Change'],
            left: 'left',
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: "{value} %"
            }
        },
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                name: 'Close Price Change',
                data: aValueList,
                markArea: {
                    data: getRecessionAreas(overheating)
                },
            },
            {
                type: 'line',
                name: 'Revenue Change',
                data: bValueList,
            },
        ]
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <DividerStyled/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        <Grid item xs={12}>
                            <ReactECharts
                                option={option}
                                style={{height: height || window.innerHeight * 0.5, width: "100%"}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider>
                                Description
                            </Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                In general price change should not exit revenue change
                            </Typography>
                        </Grid>
                    </>
            }
        </Grid>);
}