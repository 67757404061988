import {styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow} from "@mui/material";
import {percentFormatter} from "../utils/values_formats";
import React from "react";

const BodyThTableCell = styled(TableCell)(({theme}) => ({
    backgroundColor: theme.palette.grey[100],
    fontWeight: 'bold',
    borderBottom: "none",
    width: "20%",
}));

const ThTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[100],
    },
    borderBottom: "none",
}));

const TableCellNoBottomLine = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
}));

export const statStdTable = (data) => {
    return (
        <Table size="small" sx={{border: 1, borderColor: 'grey.400'}}>
            <TableHead>
                <TableRow>
                    <ThTableCell component="th" colSpan={4} align="center">Standard deviation bounds</ThTableCell>
                </TableRow>
                <TableRow>
                    <ThTableCell>STD</ThTableCell>
                    {
                        data["cnt"].map(item => (
                            <ThTableCell align="center">{item[0]}</ThTableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <BodyThTableCell component="th">Upper bound</BodyThTableCell>
                    {
                        data["upper"].map(item => (
                            <TableCellNoBottomLine
                                align="center">{percentFormatter.format(item[1])}</TableCellNoBottomLine>
                        ))
                    }
                </TableRow>
                <TableRow>
                    <BodyThTableCell component="th">Lower bound</BodyThTableCell>
                    {
                        data["lower"].map(item => (
                            <TableCellNoBottomLine
                                align="center">{percentFormatter.format(item[1])}</TableCellNoBottomLine>
                        ))
                    }
                </TableRow>
                <TableRow>
                    <BodyThTableCell component="th">Actual probability %</BodyThTableCell>
                    {
                        data["cnt"].map(item => (
                            <TableCellNoBottomLine
                                align="center">{percentFormatter.format(item[1])}</TableCellNoBottomLine>
                        ))
                    }
                </TableRow>
                <TableRow>
                    {/*(data_latter_1std, 0.6827),*/}
                    {/*(data_latter_2std, 0.9545),*/}
                    {/*(data_latter_3std, 0.9973)*/}
                    <BodyThTableCell component="th">Normal probability %</BodyThTableCell>
                    <TableCellNoBottomLine align="center">{percentFormatter.format(0.6827)}</TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">{percentFormatter.format(0.9545)}</TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">{percentFormatter.format(0.9973)}</TableCellNoBottomLine>
                </TableRow>
            </TableBody>
        </Table>);
}

export const meanTable = (data) => {
    return (
        <Table size="small" sx={{border: 1, borderColor: 'grey.400', borderTop: 0}}>
            <TableHead>
                <TableRow>
                    <ThTableCell component="th" colSpan={4} align="center">Mean changes probabilities</ThTableCell>
                </TableRow>
                <TableRow>
                    <ThTableCell/>
                    <ThTableCell align="center">Positive</ThTableCell>
                    <ThTableCell align="center">Negative</ThTableCell>
                    <ThTableCell align="center">Zero</ThTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <BodyThTableCell align="left">Mean change</BodyThTableCell>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.positive.mean)}
                    </TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.negative.mean)}
                    </TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">
                        -
                    </TableCellNoBottomLine>
                </TableRow>
                <TableRow>
                    <BodyThTableCell align="left">Frequency</BodyThTableCell>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.positive.freq)}
                    </TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.negative.freq)}
                    </TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.zero.freq)}
                    </TableCellNoBottomLine>
                </TableRow>
                <TableRow>
                    <BodyThTableCell align="left">Mean change(Probability adjusted)</BodyThTableCell>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.positive.prob_adjusted_mean)}
                    </TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">
                        {percentFormatter.format(data.negative.prob_adjusted_mean)}
                    </TableCellNoBottomLine>
                    <TableCellNoBottomLine align="center">
                        -
                    </TableCellNoBottomLine>
                </TableRow>
            </TableBody>
        </Table>
    );
}
