import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import React from "react";

export const periodMap = {
    d: "Daily",
    w: "Weekly",
    m: "Monthly",
    q: "Quarterly",
}

export default function PeriodComponent(props) {
    const {period, onPeriodChange} = props;

    return (
        <ToggleButtonGroup
            size="small"
            color="primary"
            value={period}
            exclusive
            onChange={onPeriodChange}
            aria-label="Platform"
        >
            {
                Object.keys(periodMap).map(key => (
                    <ToggleButton value={key}>{periodMap[key]}</ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    );
}