import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import {Card, CardContent, CardHeader, Paper, styled} from "@mui/material";
import GovermentBondsComponent from "../economics_leading/govermentBondsComponent";
import {FedComponent} from "../economics_coincident/fed";
import {RealGDPComponent} from "../economics_lagging/gdp";
import {CrystalBall} from "./future_tickers";
import RealRatesComponent from "../economics_leading/realRateComponent";


const Item = styled(Paper)(({theme}) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(1),
}));


export default function Home() {
    return (
        <Item elevation={0}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Real rates"/>
                        <CardContent>
                            <RealRatesComponent height={window.innerHeight * 0.3}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="FED"/>
                        <CardContent>
                            <FedComponent height={window.innerHeight * 0.3}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="US treasuries spread"/>
                        <CardContent>
                            <GovermentBondsComponent height={window.innerHeight * 0.3}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Real GDP growth"/>
                        <CardContent>
                            <RealGDPComponent height={window.innerHeight * 0.3}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardHeader title="Crystal ball" subheader="Potential outperformers\laggers in the next quarter"/>
                        <CardContent>
                            <CrystalBall height={window.innerHeight * 0.5}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Item>
    );
}
