import {styled, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {nFormatter, percentFormatter} from "../utils/values_formats";
import {green, red} from "@mui/material/colors";
import {getAnalystsREcc} from "./services/stocks_service";
import {ReactECharts} from "../utils/echarts_util";

const TableThCell = styled(TableCell)(({theme}) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[100]
}));

export function ValuationsDebtSection(props) {
    const {fin} = props;
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableThCell component="th" scope="row"/>
                        {fin.map(item => (
                            <TableThCell align="center" component="th" scope="row">
                                {
                                    item.estimate === true ? `${item.date} (e)` : item.date
                                }
                            </TableThCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            Debt
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {item.debt != null ? nFormatter.format(item.debt) : "-"}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            Net Debt/EBITDA
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {item.debt_ebitda != null ? nFormatter.format(item.debt_ebitda) + "x" : "-"}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            Net Debt/Equity
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {item.debt_equity != null ? percentFormatter.format(item.debt_equity) : "-"}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            Goodwill/Total Assets
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {item.goodwill_total_assets != null ? percentFormatter.format(item.goodwill_total_assets) : "-"}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            Free cash flow
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {item.debt_ebitda != null ? nFormatter.format(item.free_cash_flow) : "-"}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function AnalystsSectionComponent(props) {
    const {ticker} = props;
    const [loading, setLoading] = useState(false);
    const [analystsData, setAnalystsData] = useState(null);
    const dataReady = analystsData !== null;


    const series = [
        'Strong Buy',
        'Buy',
        'Hold',
        'Sell',
        'Strong Sell'
    ].map(name => {
        return {
            name,
            type: 'bar',
            stack: 'total',
            barWidth: '70%',
            label: {
                show: true,
                // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
            },
            data: dataReady ? analystsData.map(
                a => {
                    const val = a[name.toLowerCase().replace(" ", "_")];
                    return val !== 0 ? val : '-';
                }
            ) : {}
        };
    });

    const dateSeries = dataReady ? analystsData.map(a => a['date'])
        : [];

    const grid = {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    };

    const color = [
        '#3ba272',
        '#91cc75',
        '#fac858',
        '#ee6666',
        '#ff0000',
        '#490087',
    ];

    const option = {
        legend: {
            selectedMode: false
        },
        grid,
        color,
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: dateSeries
        },
        series
    };

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getAnalystsREcc(ticker);
            setAnalystsData(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (analystsData !== null) return;
        getData();
    }, []);

    return dataReady ? <>
        <ReactECharts option={option} style={{height: window.innerHeight * 0.35, width: "100%", minWidth: 450}}
                      loading={loading}/>
    </> : <></>
}

export function SurprisesSection(props) {
    const {surprises} = props;

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableThCell component="th" scope="row"/>
                        <TableThCell component="th" scope="row">
                            Estimated EPS
                        </TableThCell>
                        <TableThCell component="th" scope="row">
                            Actual EPS
                        </TableThCell>
                        <TableThCell component="th" scope="row">
                            Surprise
                        </TableThCell>
                    </TableRow>
                    {
                        surprises.map(item => (
                            <>
                                <TableRow>
                                    <TableThCell>{item.date}</TableThCell>
                                    <TableCell align="center">{item.estimated_eps}</TableCell>
                                    <TableCell align="center">{item.actual_eps}</TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            color: item.surprise >= 0 ? green[400] : red[400]
                                        }}>
                                        {
                                            item.surprise === '-' ?
                                                item.surprise
                                                :
                                                percentFormatter.format(item.surprise)
                                        }
                                    </TableCell>
                                </TableRow>
                            </>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function Valuations(props) {
    const {fin} = props;

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableThCell component="th" scope="row"/>
                        {fin.map(item => (
                            <TableThCell align="center" component="th" scope="row">
                                {item.estimate === true ? `${item.date} (e)` : item.date}
                            </TableThCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            EBITDA margin
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {percentFormatter.format(item.ebitda_margin)}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            EBIT margin
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {percentFormatter.format(item.ebit_margin)}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            NET income margin
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {percentFormatter.format(item.net_margin)}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            EPS
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {nFormatter.format(item.eps)}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            EV/Ebitda
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {nFormatter.format(item.ev_ebitda)}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableThCell component="th" scope="row">
                            EV/Ebit
                        </TableThCell>
                        {fin.map(item => (
                            <TableCell component="td" scope="row" align="center">
                                {nFormatter.format(item.ev_ebit)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}