import {Home} from "../Home"
import {About} from "../About"
import {IndexPage} from "../IndexPage";
import StockDetailsPage from "../StockPage/stock_page";
import {Odminka} from "../Odminka";
import LeadingIndicatorsPage from "../economics_leading/leadingIndicators";
import {Portfolio} from "../portfolio";
import {CoincidentPage} from "../economics_coincident";
import IsmDetailsComponent from "../idea_generation/ism/ismDetails";
import InvestorsActionsComponent from "../traders/investorsActionsComponent";
import BetaComponent from "../calculations_pages/beta_page";
import {FinancialStressIndexPage} from "../FinancialStressIndexs";
import {IndustryPage} from "../IndustryPage";

const routes = [
    {
        path: "/",
        element: Home,
        navname: "Home",
    },
    {
        path: "/p_o",
        element: Portfolio,
        navname: "Portfolio",
    },
    {
        path: "/_ind",
        element: IndustryPage,
        navname: "Industry",
    },
    {
        path: "/odminka",
        element: Odminka,
        navname: "Odminka",
    },
    {
        path: "/index",
        element: IndexPage,
        navname: "Index",
    },
    {
        path: "/company/:ticker",
        element: StockDetailsPage,
        navname: "Company",
    },
    {
        path: "/about",
        element: About,
        navname: "About",
    },
    {
        path: "/economics/l",
        element: LeadingIndicatorsPage,
        navname: "LeadingIndicators",
    },
    {
        path: "/investors",
        element: InvestorsActionsComponent,
        navname: "InvestorsActions",
    },
    {
        path: "/idea/ism",
        element: IsmDetailsComponent,
        navname: "IdeaGenerationIsm",
    },
    {
        path: "/economics/c",
        element: CoincidentPage,
        navname: "CoincidentIndicators",
    },
    {
        path: "/economics/fsi",
        element: FinancialStressIndexPage,
        navname: "FinancialStressIndex",
    },
    {
        path: "/u/b",  // u -> utils
        element: BetaComponent,
        navname: "BetaCalculation",
    },
]

export default routes;