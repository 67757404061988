import React, {useEffect, useState} from "react";
import {getRealGDPData} from "../service/economics";
import {getRecessionAreas} from "../economics_leading/utils";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";

export function RealGDPComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [rgdp, setRgdp] = useState([]);

    const getData = async () => {
        const {data} = await getRealGDPData();
        setRgdp(data);
        setLoading(false);
    };

    useEffect(() => {
        if (rgdp.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = rgdp.map((item) => {
        return item["row"].split("T")[0];
    });

    const valueList = rgdp.map((item) => {
        return item["rgdp"];
    });

    const option = {
        animation: false,
        title: {
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value'
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'line',
            name: 'Rate',
            showSymbol: false,
            data: valueList,
            markArea: {
                data: getRecessionAreas(rgdp)
            }
        }
    };

    return loading ?
        <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
        :
        <ReactECharts option={option} style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>;
}