import React, {useEffect, useState} from "react";
import {getCommoditiesCOTData} from "../service/economics";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "../economics_leading/utils";
import Grid from "@mui/material/Unstable_Grid2";

export function COTComponent(props) {
    const {height, chartSubtitle} = props;
    const [loading, setLoading] = useState(true);
    const [cot, setCot] = useState([]);

    const getData = async () => {
        if (loading) {
            const cotResponse = await getCommoditiesCOTData();
            setCot(cotResponse.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (cot.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = cot.map((item) => {
        return item["row"].split("T")[0];
    });

    const cotAluminum = cot.map((item) => {
        return item["Aluminium"];
    });

    const cotCopper = cot.map((item) => {
        return item["Copper"];
    });

    const cotOilBrend = cot.map((item) => {
        return item["Crud oil Brend"];
    });

    const cotOilWTI = cot.map((item) => {
        return item["Crude oil WTI"];
    });

    const cotGold = cot.map((item) => {
        return item["Gold"];
    });

    const cotHeatingOil = cot.map((item) => {
        return item["Heating oil"];
    });

    const cotGas = cot.map((item) => {
        return item["Natural Gas"];
    });

    const cotPalladium = cot.map((item) => {
        return item["Palladium"];
    });

    const cotPlatinum = cot.map((item) => {
        return item["Platinum"];
    });

    const cotSilver = cot.map((item) => {
        return item["Silver"];
    });

    const cotSteel = cot.map((item) => {
        return item["Steel"];
    });

    const toPercentValue = (d) => d * 100;

    const toOption = (data, title) => ({
        animation: false,
        title: {
            text: title,
            subtext: chartSubtitle || "",
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: "{value} %"
            }
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'line',
            showSymbol: false,
            data: data.map(toPercentValue),
            markArea: {
                data: getRecessionAreas(cot)
            }
        }
    });

    return loading ?
        <>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </>
        :
        <>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotAluminum, "Aluminum")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotCopper, "Copper")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotOilBrend, "Oil Brend")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotOilWTI, "Oil WTI")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotHeatingOil, "Heating Oil")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotGas, "Gas")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotGold, "Gold")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotPalladium, "Palladium")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotPlatinum, "Platinum")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotSilver, "Silver")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotSteel, "Steel")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </>
}