import {
    Card,
    CardContent,
    CardHeader,
    Link,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {green, grey, red, yellow} from "@mui/material/colors";

const CellNoBorder = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
    fontWeight: "bold",
}));

const TableCellBlackBorder = styled(TableCell)(({theme}) => ({
    borderBottom: "black",
}));

const resolveIndexUrl = (industry) => {
    const urlMap = {
        "Apparel, Leather & Allied Products": "/NAICS/sub_sector/Apparel Manufacturing",
        "Transportation Equipment": "/NAICS/sub_sector/Transportation Equipment Manufacturing",
        "Petroleum & Coal Products": "/NAICS/sub_sector/Petroleum and Coal Products Manufacturing",
        "Electrical Equipment, Appliances & Components": "/NAICS/sub_sector/Electrical Equipment, Appliance, and Component Manufacturing",
        "Miscellaneous Manufacturing": "/NAICS/sub_sector/Miscellaneous Manufacturing",
        "Machinery": "/NAICS/sub_sector/Machinery",
        "Fabricated Metal Products": "/NAICS/sub_sector/Fabricated Metal Product Manufacturing",
        "Computer & Electronic Products": "/NAICS/sub_sector/Computer and Electronic Product Manufacturing",
        "Primary Metals": "/NAICS/sub_sector/Primary Metal Manufacturing",
        "Chemical Products": "/NAICS/sub_sector/Chemical Manufacturing",
        "Food, Beverage & Tobacco Products": "/NAICS/sub_sector/Food Manufacturing",
        "Plastics & Rubber Products": "/NAICS/sub_sector/Plastics and Rubber Products Manufacturing",
        "Nonmetallic Mineral Products": "/NAICS/sub_sector/Nonmetallic Mineral Product Manufacturing",
        "Furniture & Related Products": "/NAICS/sub_sector/Furniture and Related Product Manufacturing",
        "Textile Mills": "/NAICS/sub_sector/Textile Mills",
        "Wood Products": "/NAICS/sub_sector/Wood Product Manufacturing",
        "Paper Products": "/NAICS/sub_sector/Paper Manufacturing",
        "Agriculture, Forestry, Fishing & Hunting": "/NAICS/sector/Agriculture, Forestry, Fishing and Hunting",
        "Public Administration": "/NAICS/sector/Public Administration",
        "Construction": "/NAICS/sector/Construction",
        "Professional, Scientific & Technical Services": "/NAICS/sector/Professional, Scientific, and Technical Services",
        "Retail Trade": "/NAICS/sector/Retail Trade",
        "Utilities": "/NAICS/sector/Utilities",
        "Other Services": "/NAICS/sector/Other Services (except Public Administration)",
        "Educational Services": "/NAICS/sector/Educational Services",
        "Finance & Insurance": "/NAICS/sector/Finance and Insurance",
        "Arts, Entertainment & Recreation": "/NAICS/sector/Arts, Entertainment, and Recreation",
        "Real Estate, Rental & Leasing": "/NAICS/sector/Real Estate and Rental and Leasing",
        "Health Care & Social Assistance": "/NAICS/sector/Health Care and Social Assistance",
        "Accommodation & Food Services": "/NAICS/sector/Accommodation and Food Services",
        "Mining": "/NAICS/sector/Mining",
        "Management of Companies & Support Services": "/NAICS/sector/Administrative and Support and Waste Management and Remediation Services",
        "Information": "/NAICS/sector/Information",
        "Transportation & Warehousing": "/NAICS/sector/Transportation and Warehousing",
        "Wholesale Trade": "/NAICS/sector/Wholesale Trade",
    };

    return urlMap[industry];
};

const ScoreTable = (datesLst, industriesLst, scoreType, tableRowSelection, setTableRowSelection) => {
    const tds = {};
    const indScore = [];
    const lastDate = datesLst.slice(-1)[0];

    industriesLst.forEach(industry => {
        datesLst.forEach(date => {
            const score = scoreType
                .filter(item => item.industry === industry)
                .filter(item => item.date === date)
                .map(item => item.rate)[0];

            let color;
            if (score < 0) {
                color = red[300];
            } else if (score > 0) {
                color = green[300];
            } else {
                color = yellow[300];
            }

            if (date === lastDate) {
                indScore.push({industry: industry, date: date, score: score});
            }

            const lst = tds[industry] || [];
            lst.push(
                {industry: industry, date: date, score: score, color: color}
            );
            tds[industry] = lst;
        });
    });

    const sortedIndustriesByLastDate = indScore
        .sort((a, b) => a.score < b.score ? 1 : -1)
        .map(item => item.industry);

    return (
        <TableContainer>
            <Table size="small" border={1}>
                <TableHead>
                    <TableRow>
                        <CellNoBorder/>
                        {
                            datesLst.map(item => (
                                <CellNoBorder>
                                    {item}
                                </CellNoBorder>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sortedIndustriesByLastDate.map(industry => (
                            <TableRow onMouseEnter={e => setTableRowSelection(industry)}
                                      onMouseLeave={e => setTableRowSelection("")}
                                      sx={
                                          {backgroundColor: tableRowSelection === industry ? grey[400] : "None"}
                                      }
                            >
                                <CellNoBorder>
                                    {industry}
                                    {
                                        Boolean(resolveIndexUrl(industry)) ?
                                            <Link variant="body2"
                                                  sx={{marginLeft: 2}}
                                                  href={resolveIndexUrl(industry)}
                                            >
                                                Index
                                            </Link>
                                            : ""
                                    }
                                </CellNoBorder>
                                {
                                    tds[industry].map(item => (
                                        <TableCellBlackBorder align="center" sx={{backgroundColor: item.color}}>
                                            {item.score}
                                        </TableCellBlackBorder>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export function ScoreSectionComponent(props) {
    const {scores, tableRowSelection, setTableRowSelection} = props;
    const datesUniq = new Set(scores.map(item => item["date"]));
    const industriesUniq = new Set(scores.map(item => item["industry"]));
    const datesLst = Array.from(datesUniq);
    const industriesLst = Array.from(industriesUniq);

    const mainScore = scores.filter(item => item["name"] === "main");
    const newOrdersScore = scores.filter(item => item["name"] === "new_orders");
    const productionScore = scores.filter(item => item["name"] === "production");
    const inventoriesScore = scores.filter(item => item["name"] === "inventories");

    return (
        <Grid container sx={{marginBottom: 1}}>
            <Grid item>
                <Card>
                    <CardHeader title="Main"/>
                    <CardContent>
                        {ScoreTable(datesLst, industriesLst, mainScore, tableRowSelection, setTableRowSelection)}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card>
                    <CardHeader title="New Orders"/>
                    <CardContent>
                        {ScoreTable(datesLst, industriesLst, newOrdersScore, tableRowSelection, setTableRowSelection)}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card>
                    <CardHeader title="Production"/>
                    <CardContent>
                        {ScoreTable(datesLst, industriesLst, productionScore, tableRowSelection, setTableRowSelection)}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card>
                    <CardHeader title="Inventories"/>
                    <CardContent>
                        {ScoreTable(datesLst, industriesLst, inventoriesScore, tableRowSelection, setTableRowSelection)}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}