import axiosInstance from "../config/http";
import {getUser} from "./authService";
import {tryGetAuthorized, tryPostJsonAuthorized} from "./requests";

export const uploadNewNaicsCodesFileReq = (file) => {
    let headers = {
        'content-type': file.type,
    };

    const user = getUser();
    if (user && user.token) {
        headers['Authorization'] = `Token ${user.token}`;
    }
    return axiosInstance.post(`/oooa/naics/upload`, file, {
        headers: headers
    });
}

export const updateIsmReq = (report_name, month) => {
    const url = "/oooa/ism";
    return tryPostJsonAuthorized(url, {
        "report_name": report_name,
        "month": month
    });
}

export const updateHistoricalIsmReq = () => {
    const url = "/oooa/ism";
    return tryGetAuthorized(url);
}
