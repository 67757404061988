import {Card, CardContent, CardHeader, styled} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {ChicagoStressIndexComponent} from "./chicagoStress";
import {StLouisIndexComponent} from "./stLouisStress";
import {KansasCityIndexComponent} from "./kansasCityStress";

const CardStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(1),
}));

export default function FinancialStressIndexPage(props) {

    return (
        <CardStyled>
            <CardHeader title="Financial Indexes"/>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CardStyled>
                            <CardHeader title="Chicago Financial Conditions Index"/>
                            <CardContent>
                                <ChicagoStressIndexComponent height={window.innerHeight * 0.5}/>
                            </CardContent>
                        </CardStyled>
                    </Grid>
                    <Grid item xs={12}>
                        <CardStyled>
                            <CardHeader title="St. Louis Financial Stress Index"/>
                            <CardContent>
                                <StLouisIndexComponent height={window.innerHeight * 0.5}/>
                            </CardContent>
                        </CardStyled>
                    </Grid>
                    <Grid item xs={12}>
                        <CardStyled>
                            <CardHeader title="Kansas City Stress Index"/>
                            <CardContent>
                                <KansasCityIndexComponent height={window.innerHeight * 0.5}/>
                            </CardContent>
                        </CardStyled>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyled>
    );
}