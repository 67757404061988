import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import {CircularProgress} from "@mui/material";
import {getPriceCmpData} from "./services/stocks_service";
import {ReactECharts} from "../utils/echarts_util";

function ffil(dateList, inputDateLst, inputValueLst) {
    const dateListFfilled = [];
    const valueListFfilled = [];

    let valueListIndex = 0;
    dateList.forEach(d => {
        dateListFfilled.push(d);
        if (inputDateLst.indexOf(d) === -1) {
            valueListFfilled.push(
                inputValueLst[valueListIndex - 1]
            );
        } else {
            valueListFfilled.push(
                inputValueLst[valueListIndex]
            );
            valueListIndex += 1;
        }
    });

    return [dateListFfilled, valueListFfilled];
}

export function PriceCmpComponent(props) {
    const {ticker, height} = props;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const dataPresent = Object.keys(data).length > 0;

    const getData = async () => {
        setLoading(true);
        const {data} = await getPriceCmpData(ticker);
        setData(data);
        setLoading(false);
    };

    useEffect(() => {
        if (dataPresent) return;
        getData();
    }, []);

    let priceDateList = !dataPresent ? [] : data.prices.map((item) => item["date"].split("T")[0]);
    let priceValueList = !dataPresent ? [] : data.prices.map((item) => item["adjusted_close"]);

    const epsQoQDateList = !dataPresent ? [] : data.estimates.quarterly.eps_avg.map((item) => item["date"].split("T")[0]);
    const epsQoQValueList = !dataPresent ? [] : data.estimates.quarterly.eps_avg.map((item) => item["eps"]);

    const revenueQoQDateList = !dataPresent ? [] : data.estimates.quarterly.revenue_avg.map((item) => item["date"].split("T")[0]);
    const revenueQoQValueList = !dataPresent ? [] : data.estimates.quarterly.revenue_avg.map((item) => item["revenue"]);

    let epsQoQDateListFfilled = [];
    let epsQoQValueListFfilled = [];

    let revenueQoQDateListFfilled = [];
    let revenueQoQValueListFfilled = [];

    if (dataPresent) {
        const start_date = epsQoQDateList[0];
        const startIndex = priceDateList.indexOf(start_date);
        const foundStartIndex = startIndex !== -1;
        if (foundStartIndex) {
            priceDateList = priceDateList.slice(startIndex);
            priceValueList = priceValueList.slice(startIndex);
        }

        // Fill gapes in the EPS estimates
        [epsQoQDateListFfilled, epsQoQValueListFfilled] = ffil(priceDateList, epsQoQDateList, epsQoQValueList);
        [revenueQoQDateListFfilled, revenueQoQValueListFfilled] = ffil(priceDateList, revenueQoQDateList, revenueQoQValueList);
        priceDateList = epsQoQDateListFfilled;
    }

    const toOption = (title, secondDateLst, secondValuesLst) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Price', 'Estimates'],
            left: 'left',
        },
        xAxis: [
            {
                type: 'category',
                data: priceDateList
            },
            {
                type: 'category',
                data: secondDateLst,
            }
        ],
        yAxis: [
            {
                type: 'value',
            },
            {
                nameLocation: 'start',
                type: 'value',
            }
        ],
        dataZoom: [{
            show: true,
        }],
        series: [
            {
                type: 'line',
                name: 'Price',
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: priceValueList,
                showSymbol: false,
            },
            {
                type: 'line',
                name: 'Estimates',
                showSymbol: false,

                step: 'start',
                data: secondValuesLst,
            },
        ]
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    :
                    <>
                        <Grid item xs={12}>
                            <ReactECharts
                                option={
                                    toOption(
                                        "Adj CLose VS EPS estimates",
                                        epsQoQDateListFfilled,
                                        epsQoQValueListFfilled
                                    )
                                }
                                style={{height: height || window.innerHeight * 0.6, width: "100%"}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactECharts
                                option={
                                    toOption(
                                        "Adj CLose VS Revenue",
                                        revenueQoQDateListFfilled,
                                        revenueQoQValueListFfilled
                                    )
                                }
                                style={{height: height || window.innerHeight * 0.6, width: "100%"}}
                            />
                        </Grid>
                    </>
            }
        </Grid>
    );
}