import {FETCH_ERROR, FETCH_NAICS, FETCH_GICS} from "../types"

const defaultState = {
    naics: {},
    gics: {},
    loading: false
};

export default function(state = defaultState, action){
    switch (action.type) {
        case FETCH_NAICS:
            return {
                ...state,
                naics: action.payload,
                loading: false
            }
        case FETCH_GICS:
            return {
                ...state,
                gics: action.payload,
                loading: false
            }
        case FETCH_ERROR:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}
