import React, {useEffect, useState} from "react";
import {getCommoditiesData, getFuturesData} from "../service/economics";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "./utils";
import Grid from "@mui/material/Unstable_Grid2";

export default function CommoditiesComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(false);
    const [ppi, setPpi] = useState([]);
    const [fut, setFut] = useState([]);

    const getData = async () => {
        if (!loading) {
            setLoading(true);

            const comResponse = await getCommoditiesData();
            setPpi(comResponse.data);

            const {data} = await getFuturesData();
            setFut(data);

            setLoading(false);
        }
    };

    useEffect(() => {
        if (ppi.length > 0 && fut.length > 0) return;
        getData();
    }, []);

    const ppiValueList = ppi.map((item) => item["ppi"]);

    const goldValueList = fut.map((item) => item["Gold"]);
    const silverValueList = fut.map((item) => item["Silver"]);
    const platinumValueList = fut.map((item) => item["Platinum"]);
    const copperValueList = fut.map((item) => item["Copper"]);
    const lumberValueList = fut.map((item) => item["Lumber"]);
    const brentOilValueList = fut.map((item) => item["Brent oil"]);
    const wtiOilValueList = fut.map((item) => item["WTI oil"]);

    const toOption = (title, subtitle, allData, valueList) => {
        const dateList = allData.map((item) => item["row"].split("T")[0]);
        return {
            animation: false,
            title: {
                text: title,
                subtext: subtitle,
                left: 'center'
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: dateList
            },
            yAxis: {
                type: 'value'
            },
            dataZoom: [{
                show: true,
            }],
            series: {
                type: 'line',
                showSymbol: false,
                data: valueList,
                markArea: {
                    data: getRecessionAreas(allData)
                }
            }
        }
    };

    return loading ?
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.4}/>
            </Grid>
        </Grid>
        :
        <Grid container>
            <Grid item xs={12}>
                <ReactECharts
                    option={toOption(
                        "PPI: Industrial Commodities", "cyclical commodities", ppi, ppiValueList
                    )}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts
                    option={toOption(
                        "Platinum", "futures", fut, platinumValueList
                    )}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts
                    option={toOption("Gold", "futures", fut, goldValueList)}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={4}>
                <ReactECharts
                    option={toOption("Silver", "futures", fut, silverValueList)}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption("WTI Oil", "futures", fut, wtiOilValueList)}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption("Brent Oil", "futures", fut, brentOilValueList)}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption("Copper", "futures", fut, copperValueList)}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption("Lumber", "futures", fut, lumberValueList)}
                    style={{height: height || window.innerHeight * 0.4, width: "100%"}}/>
            </Grid>
        </Grid>
}