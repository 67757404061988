import axiosInstance from "../config/http";
import {tryGetAuthorized} from "../service/requests";

export const getSimplifiedWinLosTickers = () => {
    return axiosInstance.get(`/bipbup/s`);
}

export const getWinLosTickers = () => {
    return tryGetAuthorized(`/bipbup/`);
}

export const getCausationAnalysis = (ticker) => {
    return tryGetAuthorized(`/bipbup/corrcause/${ticker}/`);
}