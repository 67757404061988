import './App.css';
import {Menu} from "./TopPageMenu"
import Feedback from "./Feedback/feedback";
import RouterComponent from "./routes/RouterComponent";


function App() {
    return (
        <div className="App">
            <Menu/>
            <RouterComponent />
            <Feedback/>
        </div>
    );
}

export default App;
