import {Card, CardContent, CardHeader, styled} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {EmploymentSituationReportComponent} from "./esr";
import {InflationComponent} from "./inflation";
import {IndustrialProductionComponent} from "./ip";
import {DurableGoodsComponent} from "./durableGoods";
import {CardDefaultMarginStyled} from "../config/constants";

const GreyCardStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[100]
}));

export default function CoincidentPageComponent() {
    return (
        <CardDefaultMarginStyled elevation={0}>
            <CardHeader title="Coincident indicators"/>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CardDefaultMarginStyled>
                            <CardHeader title="Employment situation report"/>
                            <CardContent>
                                <EmploymentSituationReportComponent topHeight={window.innerHeight * 0.5} secondHeight={window.innerHeight * 0.4} restHeight={window.innerHeight * 0.3}/>
                            </CardContent>
                        </CardDefaultMarginStyled>
                    </Grid>
                    <Grid item xs={12}>
                        <GreyCardStyled>
                            <CardHeader title="Inflation"/>
                            <CardContent>
                                <InflationComponent height={window.innerHeight * 0.3} />
                            </CardContent>
                        </GreyCardStyled>
                    </Grid>
                    <Grid item xs={12}>
                        <CardDefaultMarginStyled>
                            <CardHeader title="Industrial production"/>
                            <CardContent>
                                <IndustrialProductionComponent height={window.innerHeight * 0.3} />
                            </CardContent>
                        </CardDefaultMarginStyled>
                    </Grid>
                    <Grid item xs={12}>
                        <GreyCardStyled>
                            <CardHeader title="Durable Goods"/>
                            <CardContent>
                                <DurableGoodsComponent
                                    height={window.innerHeight * 0.4}
                                    topHeight={window.innerHeight * 0.5}  />
                            </CardContent>
                        </GreyCardStyled>
                    </Grid>
                </Grid>
            </CardContent>
        </CardDefaultMarginStyled>
    );
}