export const nFormatter = Intl.NumberFormat('en', {notation: 'compact',  maximumFractionDigits: 2});
export const dollarFormatter = Intl.NumberFormat('en', {style: 'currency', currency: 'USD'});
export const percentFormatter = Intl.NumberFormat('en', {
    style: 'percent',
    maximumFractionDigits: 2
});

export const percentFormatterOneDigit = Intl.NumberFormat('en', {
    style: 'percent',
    maximumFractionDigits: 1
});