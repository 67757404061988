import {Fab, Popover, Snackbar, TextField, Tooltip, useTheme} from "@mui/material";
import {useState} from "react";
import useForm from "./useForm";
import {validator} from "./validator";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import {Alert} from "@mui/lab";
import {sendFeedback} from "../service/feedback";
import Typography from "@mui/material/Typography";
import EmailIcon from '@mui/icons-material/Email';


export default function Feedback(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(null);
    const openedFeedbackIcon = Boolean(anchorEl);
    const theme = useTheme();

    const initState = {
        email: "",
        text: "",
        name: ""
    };

    const sendRequest = async () => {
        await sendFeedback(state);
        setSnackbarOpen(true);
    }

    const onSubmit = () => {
        sendRequest();
        handleCloseFeedbackOpen();
    };

    const {handleChange, handleSubmit, handleBlur, state, errors} = useForm({
        initState,
        callback: onSubmit,
        validator
    });

    const handleClickFeedbackOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFeedbackOpen = () => {
        setAnchorEl(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    let isValidForm = Object.values(errors).filter(error => typeof error !== "undefined").length === 0;

    return (
        <>
            <Fab
                sx={{position: 'fixed', bottom: "2%", right: "1%"}}
                name="Disturb admin"
                color="extended"
                onClick={handleClickFeedbackOpen}
            >
                <Tooltip title="Contact admin" placement="top">
                    <EmailIcon/>
                </Tooltip>
            </Fab>
            <Popover
                open={openedFeedbackIcon}
                anchorEl={anchorEl}
                onClose={handleCloseFeedbackOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        width: '20%',
                        padding: theme.spacing(2),
                    },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography align="center">
                            Send me a message ;)
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            label="Name"
                            name="name"
                            defaultValue={state.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            onBlur={handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            label="Email"
                            name="email"
                            defaultValue={state.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            onBlur={handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Message"
                            name="text"
                            multiline
                            rows={4}
                            defaultValue={state.text}
                            onChange={handleChange}
                            error={!!errors.text}
                            helperText={errors.text}
                            onBlur={handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={6}>
                        <Button
                            disabled={!isValidForm}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Popover>
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
                      open={snackbarOpen}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{width: '100%'}}>
                    Thank for your message, i will read asap(but promise you nothing).
                </Alert>
            </Snackbar>
        </>
    );
}