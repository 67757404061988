import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import {CircularProgress, Link, styled, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getPeers, getStockVsSectorValuations} from "./services/stocks_service";
import {nFormatter, percentFormatter} from "../utils/values_formats";
import routes from "../routes";
import {blueGrey} from "@mui/material/colors";
import lightGreen from "@mui/material/colors/lightGreen";
import {useSelector} from "react-redux";
import {hasPremium} from "../service/authService";

const CenteredCell = styled(TableCell)(({theme}) => ({
    textAlign: 'center',
}));

function TableEvaluationRow(props) {
    const {data, highlight, first_cell_value, second_cell_value} = props;

    let f_cell = first_cell_value ? first_cell_value : <CenteredCell><Link
        target="blank"
        href={routes.find(o => o.navname === 'Company').path.replace(":ticker", data["ticker"])}>
        {data["ticker"]}
    </Link></CenteredCell>;
    let s_cell = second_cell_value ? second_cell_value : data["name"];

    const formatNumberOrNone = (val) => val !== null ? nFormatter.format(val) : '-'
    const formatPercentOrNone = (val) => val !== null ? percentFormatter.format(val) : '-'

    return <TableRow sx={{backgroundColor: highlight ? lightGreen[100] : null}}>
        {f_cell}
        <CenteredCell>{s_cell}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["pe_curr"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["pe_next"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["peg_curr"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["peg_forward"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["eps_curr"])}</CenteredCell>
        <CenteredCell>{formatNumberOrNone(data["eps_next"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["eps_growth_curr"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["eps_growth_forward"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["revenue_growth_curr"])}</CenteredCell>
        <CenteredCell>{formatPercentOrNone(data["revenue_growth_forward"])}</CenteredCell>
    </TableRow>
}

function MyTableHeaders(props) {
    const {firstCellTitle, secondCellTitle} = props;

    return <TableHead>
        <TableRow sx={{backgroundColor: blueGrey[100]}}>
            <CenteredCell>{firstCellTitle}</CenteredCell>
            <CenteredCell>{secondCellTitle}</CenteredCell>
            <CenteredCell>PE</CenteredCell>
            <CenteredCell>PE Forward</CenteredCell>
            <CenteredCell>PEG</CenteredCell>
            <CenteredCell>PEG Forward</CenteredCell>
            <CenteredCell>EPS</CenteredCell>
            <CenteredCell>EPS Forward</CenteredCell>
            <CenteredCell>EPS growth</CenteredCell>
            <CenteredCell>EPS growth forward</CenteredCell>
            <CenteredCell>Revenue growth</CenteredCell>
            <CenteredCell>Revenue growth forward</CenteredCell>
        </TableRow>
    </TableHead>;
}

export function Peers(props) {
    const {ticker} = props;
    const [loading, setLoading] = useState(true);
    const [premiumLoading, setPremiumLoading] = useState(false);
    const [peers, setPeers] = useState(null);
    const [industryData, setIndustryData] = useState(null);
    const user = useSelector(state => state.auth.user);
    const isPremium = hasPremium(user);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getPeers(ticker);
            setPeers(data);
        } finally {
            setLoading(false);
        }
    };

    const getPremiumData = async () => {
        if (!isPremium) return;

        try {
            setPremiumLoading(true);
            const {data} = await getStockVsSectorValuations(ticker);
            setIndustryData(data);
        } finally {
            setPremiumLoading(false);
        }
    };

    const getValuationsOrDefault = (obj, key) => {
        //TODO: move to BE, but for now fuck it let it be :)
        const isNullOrUndefined = (value) => value === null || value === undefined;
        const defaultValue = {'average': {}, 'weighted': {}};

        if (isNullOrUndefined(obj)) {
            return defaultValue;
        }

        if (isNullOrUndefined(obj[key])) {
            return defaultValue;
        }

        if (isNullOrUndefined(obj[key]['valuations'])) {
            return defaultValue;
        }

        return obj[key]['valuations'];
    }

    useEffect(() => {
        if (peers === null) getData();

        if (isPremium && industryData === null) getPremiumData();

    }, []);


    const showLoadingCircle = premiumLoading && (industryData === null);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                loading ?
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                    : peers !== null ?
                        <>
                            <Grid item xs={12}>
                                <Table sx={{marginTop: 2}} size="small">
                                    <MyTableHeaders firstCellTitle={"Ticker"} secondCellTitle={"Company Name"}/>
                                    <TableBody>
                                        {
                                            peers.map(
                                                item => <TableEvaluationRow data={item} highlight={item.ticker === ticker}/>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                            {
                                isPremium ? showLoadingCircle ? <CircularProgress/> : <>
                                    <Grid item xs={12}>
                                        <Divider>Premium Section</Divider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table sx={{marginTop: 2}} size="small">
                                            <MyTableHeaders firstCellTitle={"Type"} secondCellTitle={"Name"}/>
                                            <TableBody>
                                                {
                                                    industryData['naics'] !== undefined ?
                                                        ["sector", "sub_sector", "industry"].map(key => <>
                                                            <TableEvaluationRow
                                                                first_cell_value={<TableCell>[Avg] Naics {key}</TableCell>}
                                                                second_cell_value={industryData['naics'][key]['name']}
                                                                data={getValuationsOrDefault(industryData['naics'], key)['average']}
                                                            />
                                                            <TableEvaluationRow
                                                                first_cell_value={<TableCell>[Avg Weighted]
                                                                    Naics {key}</TableCell>}
                                                                second_cell_value={industryData['naics'][key]['name']}
                                                                data={getValuationsOrDefault(industryData['naics'], key)['weighted']}
                                                            />
                                                        </>) : <></>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </> : <></>
                            }
                        </> : <></>
            }
        </Grid>
    )
}