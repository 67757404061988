import Grid from "@mui/material/Unstable_Grid2";
import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useEffect, useState} from "react";
import {getWinLosTickers} from "../brainService";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const TextCell = styled(TableCell)(({theme}) => ({
    textAlign: "center",
}));

function LinkOrEmpty(props) {
    const {ticker} = props;

    if (ticker) {
        return <Link href={`/company/${ticker}`} target="blank">{ticker}</Link>;
    }

    return <></>;
}

function MoversSection(props) {
    const {candidates, title, win} = props;
    const [openedId, setOpenedId] = useState(null);

    const dataAccessKey = win ? "winners" : "losers";

    const handleCollapse = (e) => {
        const currentTargetElement = e.currentTarget["id"];

        currentTargetElement === openedId ? setOpenedId(null) : setOpenedId(currentTargetElement);
    };

    const dataReady = candidates !== undefined && Object.keys(candidates).length > 0;

    return <Card>
        <CardHeader title={title}/>
        <CardContent>
            {
                dataReady ?
                    <List>
                        {
                            candidates[dataAccessKey].map(obj => <>
                                    {
                                        (obj["tickers"]["eps"] || []).length > 0 ? <>
                                                <ListItemButton onClick={handleCollapse} id={obj["industry"]}>
                                                    <ListItemText primary={obj["industry"]}/>
                                                    {openedId === obj["industry"] ? <ExpandLess/> : <ExpandMore/>}
                                                </ListItemButton>
                                                <Collapse in={openedId === obj["industry"]} timeout="auto"
                                                          unmountOnExit>
                                                    <List component="div" disablePadding dense={true}>

                                                        <ListItem>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                EpsGrowth+PE+RevenueGrowth
                                                                            </TableCell>
                                                                            <TableCell>EpsGrowth+PE</TableCell>
                                                                            <TableCell>EpsGrowth</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            (obj["tickers"]["eps"] || []).map((t, i) =>
                                                                                <TableRow>
                                                                                    <TextCell>
                                                                                        <LinkOrEmpty ticker={obj["tickers"]["eps+pe+revenue"][i]} />
                                                                                    </TextCell>

                                                                                    <TextCell>
                                                                                        <LinkOrEmpty ticker={obj["tickers"]["eps+pe"][i]} />
                                                                                    </TextCell>

                                                                                    <TextCell>
                                                                                        <LinkOrEmpty ticker={t} />
                                                                                    </TextCell>
                                                                                </TableRow>
                                                                            )
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </ListItem>
                                                    </List>
                                                </Collapse>
                                            </>
                                            : <ListItemText primary={obj["industry"]}/>
                                    }
                                </>
                            )
                        }
                    </List>
                    : ""
            }
        </CardContent>
    </Card>
}

export function CandidatesComponent(props) {
    const {topLvl} = props;
    const [candidates, setCandidates] = useState({});
    const [loading, setLoading] = useState(false);

    console.log(topLvl);

    const getData = async () => {
        try {
            setLoading(true);
            const {data} = await getWinLosTickers();
            setCandidates(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(candidates).length > 0) return;
        getData();
    }, []);

    return <Grid container spacing={1} sx={{marginBottom: 1}}>
        <Grid item>
            <MoversSection candidates={candidates[topLvl]} title={"Outperformers"} win={true}/>
        </Grid>
        <Grid item>
            <MoversSection candidates={candidates[topLvl]} title={"Laggers"}/>
        </Grid>
    </Grid>
}