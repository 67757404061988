export const getRecessionAreas = (data, dateKey = "row") => {
    let recessionAreas = [];
    let pair = {f: null, s: null};
    data.map((item) => {
        if (item["recession"] === 1) {
            const date = item[dateKey].split("T")[0];
            if (pair.f === null) {
                pair.f = {
                    name: 'Recession',
                    xAxis: date
                }
            } else {
                pair.s = {
                    xAxis: date
                }
            }
        } else if (pair.f !== null && pair.s !== null) {
            recessionAreas.push([pair.f, pair.s]);
            pair = {f: null, s: null};
        }
    });
    return recessionAreas;
}