import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled
} from "@mui/material";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {updateHistoricalIsmReq, updateIsmReq, uploadNewNaicsCodesFileReq,} from "../service/odminka";
import Grid from "@mui/material/Unstable_Grid2";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const CardStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(2),
}));

export default function Odminka(props) {
    const [naicsFile, setNaicsFile] = useState(null);
    const [responseRes, setResponseRes] = useState("None");
    const [ismReportName, setIsmReportName] = useState("Manufacturing");
    const [ismMonth, setIsmMonth] = useState("january");
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const isAdmin = Boolean(user) && user.is_superuser;

    useEffect(() => {
        if (isAdmin) return;

        navigate("/");
    });

    const updatingIsm = async () => {
        const r = await updateIsmReq(ismReportName, ismMonth);
        setResponseRes(`Status: ${r.status} Body: ${r.data}`)
    }

    const uploadNaics = async () => {
        const formData = new FormData();
        formData.append("file", naicsFile, naicsFile.name);

        const r = await uploadNewNaicsCodesFileReq(formData);
        setResponseRes(`Status: ${r.status} Body: ${r.data}`)
    }

    const updatingHistoricalIsm = async () => {
        const r = await updateHistoricalIsmReq();
        setResponseRes(`Status: ${r.status} Body: ${r.data}`)
    }

    const handleNaicsUpload = () => {
        setResponseRes(`Uploading ism repot`);
        uploadNaics();
    }

    const handleIsmUpdate = () => {
        setResponseRes("ISM update");
        updatingIsm();
    }

    const handleIsmHistoricalUpdate = () => {
        setResponseRes("ISM Historical update");
        updatingHistoricalIsm();
    }

    const handleNaicsFileChange = (e) => {
        if (e.target.files) {
            setNaicsFile(e.target.files[0]);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{fontWeight: "bold"}}>All fields mandatory</Typography>
            </Grid>
            <Grid items>
                <CardStyled>
                    <CardHeader title="Upload NAICS codes"/>
                    <CardContent>
                        <input
                            accept=".xlsx, .xls, .csv, .xlsm"
                            id="naics-raised-button-file"
                            multiple
                            type="file"
                            onChange={handleNaicsFileChange}
                        />
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={handleNaicsUpload} disabled={!Boolean(naicsFile)}>
                            Upload
                        </Button>
                    </CardActions>
                </CardStyled>
            </Grid>
            <Grid item>
                <CardStyled>
                    <CardHeader title="Update ISM report"/>
                    <CardContent>
                        <FormControl sx={{mr: 2}}>
                            <InputLabel id="ism-report">Report name</InputLabel>
                            <Select
                                labelId="ism-report"
                                id="ism-report-select"
                                value={ismReportName}
                                label="ISM report name"
                                onChange={(e) => setIsmReportName(e.target.value)}
                            >
                                <MenuItem value={"Services"}>ISM Services</MenuItem>
                                <MenuItem value={"Manufacturing"}>ISM Manufacturing</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{mr: 2}}>
                            <InputLabel id="ism-month">Month</InputLabel>
                            <Select
                                labelId="ism-month-label"
                                id="ism-month-select"
                                value={ismMonth}
                                label="ISM month"
                                onChange={(e) => setIsmMonth(e.target.value)}
                            >
                                {
                                    [
                                        'january', 'february', 'march', 'april', 'may', 'june',
                                        'july', 'august', 'september', 'october', 'november', 'december'
                                    ].map(item => <MenuItem
                                        value={item}>{item[0].toUpperCase() + item.slice(1)}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={handleIsmUpdate}>
                            Update
                        </Button>
                    </CardActions>
                </CardStyled>
            </Grid>
            <Grid items>
                <CardStyled>
                    <CardHeader title="Update Historical ISM"/>
                    <CardActions>
                        <Button variant="contained" onClick={handleIsmHistoricalUpdate}>
                            Update
                        </Button>
                    </CardActions>
                </CardStyled>
            </Grid>
            <Grid item xs={12}>
                <CardStyled elevation={3}>
                    <CardHeader title="Response"/>
                    <CardContent>
                        Response: {responseRes}
                    </CardContent>
                </CardStyled>
            </Grid>
        </Grid>
    )
}