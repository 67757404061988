import React, {useEffect, useState} from "react";
import {getSpeculationsData} from "../service/economics";
import {green, red} from "@mui/material/colors";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import Grid from "@mui/material/Unstable_Grid2";

export function SpeculationsComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [speculations, setSpeculations] = useState([]);

    const getData = async () => {
        const {data} = await getSpeculationsData();
        setSpeculations(data);
        setLoading(false);
    };

    useEffect(() => {
        if (speculations.length > 0) return;
        setLoading(true);
        getData();
    }, []);

    const dateList = speculations.map((item) => {
        return item["row"].split("T")[0];
    });

    const fcash_m_debtList = speculations.map((item) => {
        return item["free_cash__minus__debt"];
    });

    const toBarOption = () => ({
        animation: false,
        title: {
            text: "Investor credit",
            subtext: "(free credit cash accounts + credit balances in margin accounts) - margin debt",
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
        },
        visualMap: {
            type: 'piecewise',
            dimension: 1,
            show: false,
            min: Math.round(Math.min(...fcash_m_debtList)),
            max: 0,
            inRange: {
                color: red[400]
            },
            outOfRange: {
                color: green[400]
            },
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'bar',
            data: fcash_m_debtList,
            showSymbol: false,
        }
    });

    return loading ?
        <Grid item xs={12}>
            <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
        </Grid>
        :
        <Grid item xs={12}>
            <ReactECharts
                option={toBarOption()}
                style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
        </Grid>;
}