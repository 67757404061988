import React, {useEffect, useState} from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import {useDispatch, useSelector} from 'react-redux';
import {fetchGics, fetchNaics} from '../store/actions/classificationMappingsActions';
import Divider from '@mui/material/Divider';
import {useNavigate} from 'react-router-dom'
import {IconButton} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export default function Index() {
    const [activeClassification, setActiveClassification] = useState(null);
    const [activeIndustry, setActiveIndustry] = useState(null);
    const [activeGicsIndustry, setActiveGicsIndustry] = useState(null);
    const [activeSector, setActiveSector] = useState(null);

    const [activeSubSectorName, setActiveSubSectorName] = useState(null);
    const [activeIndustryName, _] = useState("Industries");

    const [activeSectors, setActiveSectors] = useState([]);
    const [activeSubSectors, setActiveSubSectors] = useState([]);
    const [activeIndustries, setActiveIndustries] = useState([]);
    const [activeGicsSubIndustries, setActiveGicsSubIndustries] = useState([]);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const gics = useSelector(state => state.classifications.gics);
    const naics = useSelector(state => state.classifications.naics);

    const map_ = {
        "GICS": gics, "NAICS": naics
    }

    const navigate = useNavigate();

    const handleShowSectors = (classification) => {
        setActiveSubSectors([]);
        setActiveIndustries([]);
        setActiveGicsSubIndustries([]);
        setActiveGicsIndustry(null);
        setActiveIndustry(null);
        setActiveSector(null);
        const sectors = map_[classification].map(el => el["sector"]);
        setActiveSectors([...new Set(sectors)]);
        setActiveClassification(classification);
    };

    const handleSelectSector = (sector) => {
        setActiveIndustry(null);
        setActiveIndustries([]);
        setActiveGicsSubIndustries([]);
        setActiveGicsIndustry(null);

        if (activeClassification === "GICS") {
            setActiveSubSectorName("Industry groups");
        }

        if (activeClassification === "NAICS") {
            setActiveSubSectorName("Sub sectors");
        }

        setActiveSector(sector);
        const key = activeClassification === "GICS" ? "industry_group" : "sub_sector";
        const subsectors = map_[activeClassification].filter(el => sector === el["sector"]).map(el => el[key]);
        setActiveSubSectors([...new Set(subsectors)]);
    };

    const handleSelectIndustry = (subSector) => {
        setActiveIndustry(subSector);
        setActiveGicsSubIndustries([]);
        setActiveGicsIndustry(null);

        const key = activeClassification === "GICS" ? "industry_group" : "sub_sector";
        const industries = map_[activeClassification].filter(el => subSector === el[key]).map(el => el["industry"]);
        setActiveIndustries([...new Set(industries)]);
    }

    const handleSelectGicsSubindustry = (industry) => {
        if (activeClassification === "NAICS") {
            navigate(`/${activeClassification}/industry/${industry}`);
            return;
        }

        setActiveGicsIndustry(industry);
        const subIndustries = map_[activeClassification].filter(el => industry === el["industry"]).map(el => el["sub_industry"]);
        setActiveGicsSubIndustries([...new Set(subIndustries)]);
    }

    useEffect(() => {
        const gicsReady = Object.keys(gics).length !== 0
        if (!loading && !gicsReady) {
            dispatch(fetchGics());
            setLoading(true);
        }

        const naicsReady = Object.keys(naics).length !== 0;
        if (!loading && !naicsReady) {
            dispatch(fetchNaics());
            setLoading(true);
        }

        if (gicsReady && naicsReady) {
            setLoading(false);
        }

    }, [gics, loading, naics, dispatch]);

    return (
        <Grid container spacing={2}>
            <Grid xs={12}/>
            <Grid xs={12}>
                <Typography variant="caption">Build index for a different classification systems:</Typography>
            </Grid>
            <Grid xs={12}>
                <Typography variant="h5" component="div">Classification</Typography>
            </Grid>
            <Grid xs={12} sx={{'& button': {m: 1}}}>
                {Object.keys(map_).map(key => (
                    <Button variant={activeClassification === key ? "contained" : "outlined"} size="large" key={key}
                            onClick={() => handleShowSectors(key)}>
                        {key}
                    </Button>
                ))}
            </Grid>
            <Grid xs={12} sx={{'& button': {m: 1}}}>
                {activeSectors.length > 0 ? <Divider variant="middle">Sectors:</Divider> : <></>}
                {activeSectors.map(sector => (
                    <>
                        <Button variant={activeSector === sector ? "contained" : "outlined"}
                                size="large" key={sector}
                                onClick={() => handleSelectSector(sector)}>
                            {sector}
                        </Button>
                        {/*{activeSector === sector ?*/}
                        {/*    <IconButton color="secondary" sx={{paddingLeft: 0}}>*/}
                        {/*        <SendIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*    : ""}*/}
                    </>
                ))}

                {activeSubSectors.length > 0 ? <Divider variant="middle">{activeSubSectorName}:</Divider> : <></>}
                {activeSubSectors.map(subSector => (
                    <>
                        <Button variant={activeIndustry === subSector ? "contained" : "outlined"} size="medium"
                                key={subSector}
                                onClick={() => handleSelectIndustry(subSector)}>
                            {subSector}
                        </Button>
                        {activeClassification === "NAICS" && activeIndustry === subSector ?
                            <IconButton
                                color="secondary" sx={{paddingLeft: 0}}
                                onClick={() => navigate(`/${activeClassification}/sub_sector/${subSector}`)}
                            >
                                <SendIcon/>
                            </IconButton>
                            : ""}
                    </>
                ))}

                {activeIndustries.length > 0 ? <Divider variant="middle">{activeIndustryName}:</Divider> : <></>}
                {activeIndustries.map(industry => (
                    <>
                        <Button
                            variant={activeClassification === "GICS" && activeGicsIndustry === industry ? "contained" : "outlined"}
                            size="small" key={industry}
                            onClick={() => handleSelectGicsSubindustry(industry)}>
                            {industry}
                        </Button>
                        {
                            activeClassification === "GICS" && activeGicsIndustry === industry ?
                                <IconButton
                                    color="secondary" sx={{paddingLeft: 0}}
                                    onClick={() => navigate(`/${activeClassification}/industry/${industry}`)}>
                                    <SendIcon/>
                                </IconButton>
                                : ""
                        }
                    </>
                ))}

                {activeGicsSubIndustries.length > 0 ? <Divider variant="middle">Sub-industries:</Divider> : <></>}
                {activeGicsSubIndustries.map(subIndustry => (
                    <Button variant="outlined" size="small" key={subIndustry}
                            onClick={() => navigate(`/${activeClassification}/sub_industry/${subIndustry}`)}>
                        {subIndustry}
                    </Button>
                ))}
            </Grid>
        </Grid>
    );
}
