import {Card, CardContent, CardHeader, styled} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {COTComponent} from "./commiditiesCot";
import {AAIIInvestorsSentiment} from "./aaii_investors_sentiment";
import {SpeculationsComponent} from "./speculations";
import {COTFinComponent} from "./FinancialsCot";


const CardStyled = styled(Card)(({theme}) => ({
    margin: theme.spacing(1),
}));


export default function InvestorsActionsComponent(props) {
    return (
        <CardStyled elevation={0}>
            <CardHeader title="Investors actions"/>
            <CardContent>
                <CardStyled>
                    <CardHeader title="Commitment of traders: Commodities" subheader="Net Long/Short"/>
                    <CardContent>
                        <Grid container spacing={1}>
                            <COTComponent height={window.innerHeight * 0.3}/>
                        </Grid>
                    </CardContent>
                </CardStyled>
                <CardStyled>
                    <CardHeader title="Commitment of traders: Indices" subheader="Net Long/Short"/>
                    <CardContent>
                        <Grid container spacing={1}>
                            <COTFinComponent height={window.innerHeight * 0.3}/>
                        </Grid>
                    </CardContent>
                </CardStyled>
                <CardStyled>
                    <CardHeader title="AAII Investors sentiment"/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <AAIIInvestorsSentiment height={window.innerHeight * 0.3}/>
                        </Grid>
                    </CardContent>
                </CardStyled>
                <CardStyled>
                    <CardHeader title="Speculations"/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <SpeculationsComponent height={window.innerHeight * 0.3}/>
                        </Grid>
                    </CardContent>
                </CardStyled>
            </CardContent>
        </CardStyled>
    );
}