import {FETCH_ERROR, FETCH_STOCKS} from "../types"

const defaultState = {
    stocks: [],
    error: null,
    loading: false
};

export default function (state = defaultState, action) {
    let returnState = state;
    switch (action.type) {
        case FETCH_STOCKS:
            returnState = {
                ...state,
                stocks: action.payload,
                loading: false,
                error: null
            }
            break
        case FETCH_ERROR:
            returnState = {
                ...state,
                error: action.payload,
                loading: false
            }
            break
    }
    return returnState;
}
