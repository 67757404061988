import axiosInstance from "../../config/http";
import {tryGetAuthorized, tryPostJsonAuthorized} from "../../service/requests";

export const listStocks = () => {
    return axiosInstance.get("/stocks");
}

export const getCompanyOutlook = (ticker) => {
    return tryGetAuthorized(`/company/outlook/${ticker}`)
}

export const getAnalystsREcc = (ticker) => {
    return axiosInstance.get(`/company/analysts_recc/${ticker}`)
}

export const getFinancials = (ticker) => {
    return tryGetAuthorized(`/company/fin/${ticker}`)
}

export const getExecutives = (ticker) => {
    return tryGetAuthorized(`/company/ex/${ticker}`)
}

export const postExecutivesDetails = (ticker, companyName, executiveName, executiveTitle, yearBorn) => {
    return tryPostJsonAuthorized(
        `/company/ex/${ticker}/premium`,
        {
            "full_company_name": companyName,
            "executive_name": executiveName,
            "executive_title": executiveTitle,
            "year_born": yearBorn,
        }
    )
}

export const getTA = (ticker, period="d") => {
    return axiosInstance.get(`/company/ta/${ticker}?period=${period}`)
}

export const getPeers = (ticker) => {
    return axiosInstance.get(`/company/peers/${ticker}`)
}

export const getStockVsSectorValuations = (ticker) => {
    return tryGetAuthorized(`/company/${ticker}/premium/cmp/industry`)
}

export const getAdministrationInnovation = (ticker) => {
    return tryGetAuthorized(`/company/adminn/${ticker}`);
}

export const getOverheat = (ticker) => {
    return tryGetAuthorized(`/company/overheat/${ticker}`);
}

export const getEarningCallData = (ticker) => {
    return tryGetAuthorized(`/company/ec/${ticker}`);
}

export const getCatalystData = (ticker) => {
    return tryGetAuthorized(`/company/${ticker}/premium/catalyst`);
}

export const getPriceCmpData = (ticker) => {
    return tryGetAuthorized(`/company/${ticker}/premium/price_cmp`);
}



