import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import {NavLink, useNavigate} from "react-router-dom";
import routes from "../routes";
import {
    alpha,
    Autocomplete,
    Dialog,
    DialogContent,
    InputBase,
    List,
    ListItemButton,
    ListItemText,
    Popover,
    styled,
    TextField
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import AuthComponent from "../auth/authComponent";
import {useDispatch, useSelector} from "react-redux";
import {stocksReq} from "../store/actions/stocksActions";


const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        width: '10%',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`
    },
}));

const TopPageMenu = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [economicsPoper, setEconomicsPoper] = useState(null);
    const [utilitiesPoper, setUtilitiesPoper] = useState(null);
    const user = useSelector(state => state.auth.user);
    const stocks = useSelector(state => state.stocks.stocks);
    const tickers = stocks.tickers || [];
    const isAdmin = Boolean(user) && !!user.is_superuser;
    const dispatch = useDispatch();

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleEconomicsClose = () => setEconomicsPoper(null);

    const handleUtilsClose = () => setUtilitiesPoper(null);

    const economicsOpenHandler = (e) => setEconomicsPoper(e.currentTarget);

    const utilitiesOpenHandler = (e) => setUtilitiesPoper(e.currentTarget);

    useEffect(() => {
        dispatch(stocksReq());
    }, []);

    const handleNavigate = (event, values) => {
        navigate(
            `${routes.find(o => o.navname === 'Company').path.replace(":ticker", values)}`,
        );
        handleClose();
    }

    const openEconomicsPopover = Boolean(economicsPoper);
    const openUtilitiesPopover = Boolean(utilitiesPoper);
    const id = openEconomicsPopover ? 'economics-popover' : undefined;
    const utilitiesId = openEconomicsPopover ? 'utilities-popover' : undefined;

    return (
        <>
            <AppBar position="sticky">
                <CssBaseline/>
                <Toolbar>
                    <Button
                        component={NavLink}
                        to={routes.find(o => o.navname === 'Home').path}
                        color="inherit">
                        Home
                    </Button>
                    <Button
                        aria-describedby={id}
                        color="inherit"
                        onClick={economicsOpenHandler}>
                        Economics
                    </Button>
                    <Popover
                        id={id}
                        open={openEconomicsPopover}
                        anchorEl={economicsPoper}
                        onClose={handleEconomicsClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <List dense>
                            <ListItemButton component={NavLink}
                                            onClick={handleEconomicsClose}
                                            to={routes.find(o => o.navname === 'LeadingIndicators').path}>

                                <ListItemText primary='Leading'/>
                            </ListItemButton>
                            <ListItemButton component={NavLink}
                                            onClick={handleEconomicsClose}
                                            to={routes.find(o => o.navname === 'CoincidentIndicators').path}>
                                <ListItemText primary='Coincident'/>
                            </ListItemButton>
                            <ListItemButton component={NavLink}
                                            onClick={handleEconomicsClose}
                                            to={routes.find(o => o.navname === 'FinancialStressIndex').path}>
                                <ListItemText primary='Financial Stress indexes'/>
                            </ListItemButton>
                        </List>
                    </Popover>
                    <Button
                        component={NavLink}
                        to={routes.find(o => o.navname === 'InvestorsActions').path}
                        color="inherit">
                        Investors
                    </Button>
                    <Button
                        component={NavLink}
                        to={routes.find(o => o.navname === 'Industry').path}
                        color="inherit">
                        Industry
                    </Button>
                    <Button
                        aria-describedby={utilitiesId}
                        color="inherit"
                        onClick={utilitiesOpenHandler}>
                        Calculations
                    </Button>
                    <Popover
                        id={utilitiesId}
                        open={openUtilitiesPopover}
                        anchorEl={utilitiesPoper}
                        onClose={handleUtilsClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <List dense>
                            <ListItemButton component={NavLink}
                                            onClick={handleUtilsClose}
                                            to={routes.find(o => o.navname === 'BetaCalculation').path}>
                                <ListItemText primary='Beta calculations'/>
                            </ListItemButton>
                        </List>
                    </Popover>
                    <Button
                        component={NavLink}
                        to={routes.find(o => o.navname === 'Portfolio').path}
                        color="inherit">
                        Portfolio Modeling
                    </Button>
                    <Button
                        component={NavLink}
                        to={routes.find(o => o.navname === 'About').path}
                        color="inherit">
                        About
                    </Button>
                    {
                        isAdmin ? <>
                            <Button
                                component={NavLink}
                                to={routes.find(o => o.navname === 'IdeaGenerationIsm').path}
                                color="inherit">
                                ISM Idea Generation
                            </Button>
                            <Button
                                component={NavLink}
                                to={routes.find(o => o.navname === 'Odminka').path}
                                color="inherit">
                                Odminka Sir
                            </Button>
                        </> : ""
                    }
                    <Typography
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}/>
                    <Search onClick={handleClickOpen}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search ticker"
                            inputProps={{'aria-label': 'search'}}
                        />
                    </Search>
                    <AuthComponent/>
                </Toolbar>
            </AppBar>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent>
                    <Autocomplete
                        id="find-ticker"
                        freeSolo
                        options={tickers}
                        onChange={handleNavigate}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                label="Ticker"
                            />
                        }
                    />
                </DialogContent>
            </Dialog>
        </>
    )
        ;
}

export default TopPageMenu;
