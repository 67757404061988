import {styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {blueGrey} from "@mui/material/colors";
import {dollarFormatter, nFormatter} from "../utils/values_formats";

const CollSpannedTh = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
    backgroundColor: blueGrey[200],
}));

const BodyTh = styled(TableCell)(({theme}) => ({
    fontWeight: "bold"
}));

const BodyThTotal = styled(TableCell)(({theme}) => ({
    fontWeight: "bold",
    borderBottom: "none",
}));

const TdNoBottomBorder = styled(TableCell)(({theme}) => ({
    borderBottom: "none",
}));

const totalTableRow = (stat) => (
    <TableRow>
        <BodyThTotal colSpan={4} align="right">Total</BodyThTotal>
        <TdNoBottomBorder align="center">{dollarFormatter.format(stat.net_exposure)}</TdNoBottomBorder>
        <TdNoBottomBorder align="center">{dollarFormatter.format(stat.gross_exposure)}</TdNoBottomBorder>
        <TdNoBottomBorder align="center">{nFormatter.format(stat.net_weight)}</TdNoBottomBorder>
        <TdNoBottomBorder align="center">{nFormatter.format(stat.net_weighted_beta)}</TdNoBottomBorder>
    </TableRow>
);

const VolATable = (props) => {
    const {title, data} = props;

    const dataTableRow = (ticker, stat) => (
        <TableRow>
            <BodyTh>{ticker}</BodyTh>
            <TableCell align="center">{nFormatter.format(stat.beta)}</TableCell>
            <TableCell align="center">{dollarFormatter.format(stat.price)}</TableCell>
            <TableCell align="center">{stat.shares}</TableCell>
            <TableCell align="center">{dollarFormatter.format(stat.net_exposure)}</TableCell>
            <TableCell align="center">{dollarFormatter.format(stat.gross_exposure)}</TableCell>
            <TableCell align="center">{nFormatter.format(stat.net_weight)}</TableCell>
            <TableCell align="center">{nFormatter.format(stat.net_weighted_beta)}</TableCell>
        </TableRow>
    );

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <CollSpannedTh colSpan={8} align="center">{title}</CollSpannedTh>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ticker</TableCell>
                        <TableCell>5Y Beta</TableCell>
                        <TableCell>Ticker Price</TableCell>
                        <TableCell>Shares to buy</TableCell>
                        <TableCell>Net exposure in $</TableCell>
                        <TableCell>Gross exposure in $</TableCell>
                        <TableCell>Net weight</TableCell>
                        <TableCell>Net weighted beta</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(data).filter(key => key !== "total").map(key => dataTableRow(key, data[key]))
                    }
                    {
                        totalTableRow(data["total"])
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TotalTable(props) {
    const {stat} = props;

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <CollSpannedTh colSpan={8} align="center">Entire Portfolio</CollSpannedTh>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">Net exposure in $</TableCell>
                        <TableCell align="center">Gross exposure in $</TableCell>
                        <TableCell align="center">Net weight</TableCell>
                        <TableCell align="center">Net weighted beta</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TdNoBottomBorder align="center">{dollarFormatter.format(stat.net_exposure)}</TdNoBottomBorder>
                        <TdNoBottomBorder
                            align="center">{dollarFormatter.format(stat.gross_exposure)}</TdNoBottomBorder>
                        <TdNoBottomBorder align="center">{nFormatter.format(stat.net_weight)}</TdNoBottomBorder>
                        <TdNoBottomBorder align="center">{nFormatter.format(stat.beta)}</TdNoBottomBorder>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function VolatilityComponent(props) {
    const {volatility} = props;
    return (
        <>
            {
                Object.values(volatility.l).length > 1 ? <VolATable data={volatility.l} title={"Long Positions"}/> : ""
            }
            {
                Object.values(volatility.s).length > 1 ? <VolATable data={volatility.s} title={"Short Positions"}/> : ""
            }
            <TotalTable stat={volatility.portfolio}/>
        </>
    );
}