import React, {useEffect, useState} from "react";
import {getFinancialsCOTData} from "../service/economics";
import {Skeleton} from "@mui/material";
import {ReactECharts} from "../utils/echarts_util";
import {getRecessionAreas} from "../economics_leading/utils";
import Grid from "@mui/material/Unstable_Grid2";

export function COTFinComponent(props) {
    const {height} = props;
    const [loading, setLoading] = useState(true);
    const [cot, setCot] = useState([]);

    const getData = async () => {
        setLoading(true);
        const cotResponse = await getFinancialsCOTData();
        setCot(cotResponse.data);
        setLoading(false);
    };

    useEffect(() => {
        if (cot.length > 0) return;
        getData();
    }, []);

    const dateList = cot.map((item) => {
        return item["row"].split("T")[0];
    });

    const cotDJIA = cot.map((item) => {
        return item["DJIA"];
    });

    const cotSP500 = cot.map((item) => {
        return item["SP500"];
    });

    const cotNasdaq100 = cot.map((item) => {
        return item["Nasdaq100"];
    });

    const cotDevelopedMarkets = cot.map((item) => {
        return item["MSCI Developed Markets"];
    });

    const cotEmergingMarkets = cot.map((item) => {
        return item["MSCI Emerging Markets"];
    });

    const cotBloombergCommodities = cot.map((item) => {
        return item["Bloomberg Commodity Index"];
    });

    const cot10YBond = cot.map((item) => {
        return item["US 10 Year Treasury"];
    });

    const cot2YBond = cot.map((item) => {
        return item["US 2 Year Treasury"];
    });

    const cotVix = cot.map((item) => {
        return item["VIX"];
    });

    const toPercentValue = (d) => d * 100;

    const toOption = (data, title) => ({
        animation: false,
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: dateList
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: "{value} %"
            }
        },
        dataZoom: [{
            show: true,
        }],
        series: {
            type: 'line',
            showSymbol: false,
            data: data.map(toPercentValue),
            markArea: {
                data: getRecessionAreas(cot)
            }
        }
    });

    return loading ?
        <>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" variant="rectangular" height={height || window.innerHeight * 0.5}/>
            </Grid>
        </>
        :
        <>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotDJIA, "Dow Jones Industrial Average")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotSP500, "S&P 500")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotNasdaq100, "Nasdaq 100")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotDevelopedMarkets, "MSCI Developed Markets")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotEmergingMarkets, "MSCI Emerging Markets")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotBloombergCommodities, "Bloomberg Commodities")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cotVix, "Vix")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cot10YBond, "US 10Y Treasuries")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
            <Grid item xs={3}>
                <ReactECharts
                    option={toOption(cot2YBond, "US 2Y Treasuries")}
                    style={{height: height || window.innerHeight * 0.5, width: "100%"}}/>
            </Grid>
        </>
}